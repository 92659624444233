/**
 * Created by BETALOS on 31/12/2016.
 */
(function () {
    'use strict';

    const VISIT_FORMS_DIALOG = require('parameters/dialogs/visit-forms-submodule/visit-forms-submodule-dialog')

    module.exports = GeneralConfigCtrl;

    GeneralConfigCtrl.$inject = [
        '$scope', 'configService', 'dragulaService', '$mdToast', '$translate', '$timeout', 'visitService',
        'dictionaryService', 'WorkFlowService', 'frontDeskService', 'formsService', '$mdDialog', 'storageService'
    ];

    function GeneralConfigCtrl(
        $scope, configService, dragulaService, $mdToast, $translate, $timeout, visitService, dictionaryService,
        WorkFlowService, frontDeskService, formsService, $mdDialog, storageService
    ) {

        let vm = this;

        let saveMsg = _.mnDelay(saveMsgFunc, 100);
        let items = require('../json/visit-sub-links.json');

        vm.$onInit = init;

        vm.handleConfig = _.mnDelay(handleConfig, 750);
        vm.updateDefault = _.mnDelay(startAutoSave, 750);
        vm.handlePrescriptionFieldsConfig = _.mnDelay(handlePrescriptionFieldsConfig, 750);

        vm.handleWorkFlowConfig = _.mnDelay(handleWorkFlowConfig, 750);
        vm.handleOphtalmicFieldsConfig = _.mnDelay(handleOphtalmicFieldsConfig, 750);

        // active modules
        vm.handleActiveModules = _.mnDelay(handleActiveModules, 750);

        // sms related
        vm.handleVideoCall = _.mnDelay(handleVideoCall, 750);

        vm.removeWorkflow = removeWorkflow;
        vm.handleWorkflowView = handleWorkflowView;
        vm.workflowColumnLabel = workflowColumnLabel;
        vm.workflowFormGoBack = workflowFormGoBack;
        vm.workflowFormSubmit = workflowFormSubmit;

        vm.useSubModule = useSubModule;
        vm.removeSubModule = removeSubModule;

        // let simpleToast = $mdToast.simple()
        //     .textContent($translate.instant('exam_setup_edit_success'))
        //     .position("bottom left")
        //     .hideDelay(1500);

        vm.setFavorite = setFavorite;

        function init() {
            vm.hasStock = configService.hasStock();
            vm.activeModules = _.cloneDeep(configService.activeModules);

            vm.default = _.cloneDeep(configService.defaultValues);
            vm.hasOphthalmic = _.get(configService.activeModules, 'has_ophthalmic_prescription', false);
            vm.workflowColumns = WorkFlowService.getColumnConfiguration();
            vm.dictionaryGroup = dictionaryService.getDictionaryGroups();

            vm.formSubmodules = [];
            vm.toolbarLinks = [];
            vm.toolbarFormSubmodules = [];

            frontDeskService.agendaSubject
                .subscribe(data => vm.frontDeskAgendas = _.chain(data).filter(['is_disabled', false]).sortBy('name').value());

            WorkFlowService.getWorkflowViews()
                .then(data => vm.workflowViews = data);

            vm.promise = configService.get([
                "visit_links_config",
                "prescription_fields_config",
                "ophtalmic_fields_config",
                "work_flow_config",
                "video_call_config",
                "toolbar_links_config"
            ], true).then(success);

            dragulaService.options($scope, "visit-links-bag", {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });

            dragulaService.options($scope, "toolbar-links-bag", {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });

            dragulaService.options($scope, 'workflow-bag', {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });

            function success({
                                 visit_links_config, prescription_fields_config, ophtalmic_fields_config,
                                 work_flow_config, video_call_config, toolbar_links_config
                             }) {

                dragulaService.find($scope, "visit-links-bag")
                    .drake
                    .on("dragend", _.mnDelay(() => handleOrder("visitLinks"), 400));

                dragulaService.find($scope, "toolbar-links-bag")
                    .drake
                    .on("dragend", _.mnDelay(() => handleOrder("toolbarLinks"), 400));

                if (!configService.isDental()) {
                    _.remove(items, ['key', 'DENTAL']);
                    _.remove(items, ['key', 'PERIODONTAL']);
                }

                if (!configService.hasMedicalCare()) {
                    _.remove(items, ['key', 'MEDICAL_CARE']);
                }

                vm.config = visit_links_config;
                vm.toolbarConfig = toolbar_links_config || {forms: [], order: []};
                vm.workFlowConfig = work_flow_config || {};
                vm.ophtalmicFieldsConfig = ophtalmic_fields_config || {};
                vm.prescriptionFieldsConfig = prescription_fields_config || {};
                vm.videoCallConfig = video_call_config || {};

                vm.workflowForm = false;
                //vm.workflowConfig = data['work_flow_config'] || {};

                reloadTable();
            }
        }

        function startAutoSave() {
            configService.set({"default_values": vm.default}, true)
                .then(done);

            function done() {
                storageService.removeKey("default-values");
                configService.defaultValues = _.cloneDeep(vm.default);
                saveMsg();
            }
        }

        function reloadTable() {
            formsService.getFormTemplateCategories()
                .then(data => {
                    if (!!vm.config.forms) {
                        vm.formSubmodules = _.filter(data, item => !_.includes(_.map(vm.config.forms, "category"), item.id));
                    }

                    if (!!vm.toolbarConfig.forms) {
                        vm.toolbarFormSubmodules = _.filter(data, item => !_.includes(_.map(vm.toolbarConfig.forms, "category"), item.id));
                    }
                });

            if (!!vm.config.forms) {
                vm.visitSubLinks = _.concat(items, vm.config.forms);
                vm.visitSubLinks = _.orderBy(vm.visitSubLinks, item => _.get(vm.config, `order.${item.key}`), -1);
            } else {
                vm.visitSubLinks = vm.config.order ? _.orderBy(items, item => _.get(vm.config, `order.${item.key}`)) : items;
            }

            if (!!vm.toolbarConfig.forms) {
                vm.toolbarLinks = _.cloneDeep(vm.toolbarConfig.forms);
                vm.toolbarLinks = _.orderBy(vm.toolbarLinks, item => _.get(vm.toolbarConfig, `order.${item.key}`), -1);
            }
        }

        function handleConfig() {
            return configService.set({"visit_links_config": vm.config, "toolbar_links_config": vm.toolbarConfig}, true)
                .then(() => {
                    storageService.removeKey("visit-links");
                    storageService.removeKey("toolbar-links");
                    visitService.visitSubLinks.next(vm.config);
                    saveMsg();
                });
        }

        function useSubModule(item, ev, config = null, link = 'app.visit.forms') {
            if (!config) config = vm.config;

            $mdDialog.show(
                _.assign(VISIT_FORMS_DIALOG, {
                    targetEvent: ev,
                    locals: {
                        model: {
                            "key": `FORMS_C${item.id}`,
                            "icon": "mdi-form-select",
                            "title": item.name,
                            "is_form": true,
                            "link": `${link}({category:${item.id}})`,
                            "category": item.id
                        }
                    }
                })
            ).then(data => {
                if (data) {
                    config.forms.push(data);
                    handleConfig().then(() => reloadTable());
                }
            }, _.noop);
        }

        function removeSubModule(item, config = null) {
            if (!config) config = vm.config;

            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title($translate.instant('visit_forms.delete_warning_title'))
                    .textContent($translate.instant('visit_forms.delete_warning_text'))
                    .ariaLabel('delete_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(() => {
                _.remove(config.forms, ['category', item.category]);
                handleConfig().then(() => reloadTable());
            }, _.noop);
        }

        function handlePrescriptionFieldsConfig() {
            configService.set({"prescription_fields_config": vm.prescriptionFieldsConfig}, true)
                .then(saveMsg);
        }

        function handleOphtalmicFieldsConfig() {
            configService.set({"ophtalmic_fields_config": vm.ophtalmicFieldsConfig}, true)
                .then(saveMsg);
        }

        function handleActiveModules() {
            configService.set({"active_modules": vm.activeModules})
                .then(() => {
                    configService.activeModules = _.cloneDeep(vm.activeModules);
                    saveMsg();
                });
        }

        function handleVideoCall() {
            configService.set({"video_call_config": vm.videoCallConfig})
                .then(saveMsg);
        }

        function handleWorkFlowConfig() {
            configService.set({"work_flow_config": vm.workFlowConfig}, true)
                .then(saveMsg);
        }

        function handleOrder(config = "visitLinks") {
            switch (config) {
                case "visitLinks":
                    vm.config['order'] = _.reduce(
                        vm.visitSubLinks, (config, item, key) => _.assign({}, config, {[item.key]: key}), {}
                    );
                    break;
                case "toolbarLinks":
                    vm.toolbarConfig['order'] = _.reduce(
                        vm.toolbarLinks, (config, item, key) => _.assign({}, config, {[item.key]: key}), {}
                    );
                    break;

            }

            handleConfig();
        }

        function removeWorkflow(item, $event) {
            WorkFlowService.removeWorkflowView(item, $event)
                .then(() => {
                    vm.workflowViews = _.without(vm.workflowViews, item);
                    saveMsg();
                });
        }

        function handleWorkflowView(item) {
            if (_.isNil(item)) {
                vm.workflowModel = {
                    agendas: [],
                    columns: _.map(vm.workflowColumns, column => {
                        return _.chain(column).pick(['key']).set('disabled', false).value();
                    })
                };
            } else {
                // for update column purpose
                vm.workflowModel = item;
                vm.workflowModel.columns = _.chain(vm.workflowColumns)
                    .orderBy(column => _.findIndex(vm.workflowModel.columns, ['key', column.key]))
                    .map(column => {
                        const existence = _.find(vm.workflowModel.columns, ['key', column.key]);

                        if (existence) return existence;
                        else return _.chain(column).pick(['key']).set('disabled', true).value();
                    }).value();
            }

            vm.workflowForm = true;
        }


        function workflowColumnLabel(column) {
            return _.chain(vm.workflowColumns).find(['key', column.key]).get("label", "---").value()
        }

        function workflowFormGoBack() {
            vm.workflowModel = null;
            vm.workflowForm = false;
        }

        function workflowFormSubmit() {
            WorkFlowService.saveWorkflowView(vm.workflowModel)
                .then(() => {
                    WorkFlowService.getWorkflowViews()
                        .then(data => vm.workflowViews = data);
                });
        }

        function setFavorite(subLink) {
            vm.config['favorite'] = subLink.key;
            vm.handleConfig()
        }

        function saveMsgFunc() {
            let simpleToast = $mdToast.simple()
                .textContent($translate.instant('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(1500);
            let saveMsg = _.mnDelay(saveMsgFunc, 100);
            $mdToast.show(simpleToast);
        }
    }

})();
