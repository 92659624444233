/**
 * Created by amine on 27/07/2016. edited 25/02/2020
 */

(function () {

    'use strict';

    const getObjectId = require('shared/utils/get-object-id');
    const SHOW_PROCEDURES_KEY = 'entry_payment_dialog.show_details'

    class EntryPaymentDialogCtrl {
        constructor(visitService, $mdDialog, paymentService, billingService, $q, moment) {
            this.visitService = visitService;
            this.dialog = $mdDialog;
            this.paymentService = paymentService;
            this.billingService = billingService;
            this.q = $q;
            this.moment = moment;

            this.readOnly = false;
            this.newPayment = false;
            this.lockPhysician = false;
            this.payment = {};

            this.transactionUID = getObjectId();
            this.newUID = _.mnDelay(() => {
                this.transactionUID = getObjectId();
            }, 500);

            this.showProcedures = JSON.parse(localStorage.getItem(SHOW_PROCEDURES_KEY)) || false;
        }

        static get $inject() {
            return ["visitService", "$mdDialog", "paymentService", "billingService", "$q", "moment"];
        }

        get amountMinimum() {
            return _.get(this.payment, "organization_affectation.affected_amount", 0) > 0 ? 0 : 1;
        }

        get amountMaximum() {
            if (!this.newPayment) {
                const maxTotal = this.payment.total - _.get(this.visit, "financial_status.global_discount", 0);
                const remainingAmount = maxTotal - _.get(this.visit, "financial_status.paid_amount", 0);
                return remainingAmount - _.get(this.payment, "organization_affectation.affected_amount", 0);
            } else {
                const remainingAmount = this.payment.total - _.get(this.visit, "financial_status.global_discount", 0);
                return remainingAmount - _.get(this.payment, "organization_affectation.affected_amount", 0);
            }
        }

        $onInit() {
            this.visit = this.visit || -1;
            this.entry = this.entry || {};
            this.error = this.error || null;

            let promises = [this.paymentService.visitPayments(this.visit)];
            if (!_.isObject(this.visit)) promises.push(this.visitService.getVisit(this.visit));

            this.q.all(promises).then(data => {
                this.visit = _.get(data, '1', this.visit);
                this.handlePayments(data[0]);
            })
        }

        showProceduresChange() {
            localStorage.setItem(SHOW_PROCEDURES_KEY, `${this.showProcedures}`);
        }

        handlePayments(payments) {
            this.isClosed = this.visit.financial_status.is_closed;
            this.readOnly = _.size(payments) > 0 || this.visit.financial_status.is_exempt;

            const physician = _.get(payments, "0.encasement.physician");
            const payee = {
                _module: "patient.models",
                _model: "Patient",
                id: this.visit.patient_id
            };

            this.lockPhysician = !!physician;
            if (this.lockPhysician) this.payment.physician = physician;

            if (!this.readOnly) {
                this.visit.financial_status.init_discount = this.visit.financial_status.global_discount;
                const gross_remaining_amount = this.visit.financial_status.remaining_amount + this.visit.financial_status.global_discount;

                const payment_mode = _.assign(this.payment.payment_mode, {
                    due_date: this.moment()
                });

                let amount = this.newPayment ? this.visit.financial_status.remaining_amount : this.visit.financial_status.total;
                amount = this.visit.financial_status.organization_affectation ? (amount - this.visit.financial_status.organization_affectation.affected_amount) : amount;

                this.payment = _.assign(this.payment, {
                    payer: payee,
                    payer_type: this.visit.financial_status.organization_affectation ? "T" : "P",
                    payee,
                    payee_type: "P",
                    payment_date: this.moment(),
                    total: this.newPayment ? gross_remaining_amount : this.visit.financial_status.gross_total,
                    amount: amount,
                    organization_affectation: this.visit.financial_status.organization_affectation || null,
                    payment_mode,
                    comment: this.newPayment ? _.get(this.payment, "encasement.comment") : ""
                });
            } else {
                const payment = _.last(payments);
                const organization_affectation = _.get(this.visit.financial_status, "organization_affectation", {});
                this.payment = _.assign(this.payment, {
                    payer: _.get(payment, "encasement.payer"),
                    payer_type: _.isEmpty(organization_affectation) ? _.get(payment, "encasement.payer_type") : 'T',
                    payment_mode: _.get(payment, "encasement.payment_mode"),
                    payee: payee,
                    payee_type: "P",
                    payment_date: this.visit.financial_status.is_exempt ? this.visit.financial_status.exempt_at : payment.payment_date,
                    total: this.visit.financial_status.gross_total,
                    amount: this.visit.financial_status.paid_amount,
                    organization_affectation: organization_affectation,
                    comment: _.get(payment, "encasement.comment")
                });
            }
        }

        exemptVisit(deferred) {
            this.paymentService.exemptVisit(this.visit, this.payment.payment_date)
                .then(data => this.submitSuccess(data, deferred), deferred.reject);
        }

        savePayment(deferred, event) {
            this.visit.financial_status = _.assign(this.visit.financial_status, {
                paid_amount: this.newPayment ? this.visit.financial_status.paid_amount + this.payment.amount : this.payment.amount,
                is_validated: true,
                validate_at: this.moment(),
                organization_affectation: this.payment.payer_type === 'T' ? _.get(this.payment, "organization_affectation") : null
            });

            if (this.payment.amount > 0) {
                let payment = {
                    transaction_uid: this.transactionUID,
                    total_amount: this.payment.amount,
                    payment_date: this.payment.payment_date,
                    details: [{
                        total_amount: this.payment.amount,
                        visit: this.visit,
                        _cls: this.paymentService.getCls('visit')
                    }],
                    encasement: {
                        encasement_date: this.payment.payment_date,
                        physician: this.payment.physician,
                        total_amount: this.payment.amount,
                        payer: this.payment.payer,
                        payee: this.payment.payee,
                        payment_mode: this.payment.payment_mode,
                        transaction_uid: this.transactionUID,
                        comment: this.payment.comment
                    }
                };

                this.paymentService.addPayment(payment)
                    .then(data => this.submitSuccess(data, deferred, event), deferred.reject);
            } else if (_.get(this.payment, "organization_affectation.affected_amount", 0) > 0) {
                this.visitService.updateVisit(this.visit, ["financial_status"])
                    .then(data => this.submitSuccess(data, deferred, event), deferred.reject);
            }
        }

        submit(event) {
            const deferred = this.q.defer();

            if (this.visit.financial_status.is_exempt) this.exemptVisit(deferred);
            else this.savePayment(deferred, event);

            return deferred.promise;
        }

        submitSuccess(data, deferred, event = null) {
            const delivery_document = this.visit.delivery_document;

            if (_.get(this.entry, "has_encasement", false)) {
                const encasement = _.isNumber(this.entry.encasement) ? {id: this.entry.encasement} : this.entry.encasement;
                console.log(encasement);
                this.paymentService.deleteEncasement(encasement, event, false)
                    .then(() => console.log(2));
            }

            if (_.isNil(delivery_document)) {
                this.newUID();
                deferred.resolve(true);
                this.dialog.hide(data);
            } else {
                this.paymentService.validateDeliveryDocument(delivery_document)
                    .then(data => {
                        this.newUID();
                        deferred.resolve(true);
                        this.dialog.hide(data)
                    });
            }
        }

        payerChange() {
            let organization = _.get(this.visit, "insurance.organization");
            let patient = {
                _module: "patient.models",
                _model: "Patient",
                id: this.visit.patient_id
            };
            switch (this.payment.payer_type) {
                case "P":
                    this.payment = _.assign(this.payment, {
                        payer: patient
                    });
                    break;
                case "T":
                    this.payment = _.assign(this.payment, {
                        payer: patient,
                        organization_affectation: {
                            organization: organization,
                            affected_percentage: _.get(organization, "default_refund_amount", 0)
                        }
                    });
                    this.applyAffectation('percentage', this.visit, this.payment.organization_affectation);
                    break;
                case "O":
                    this.payment = _.assign(this.payment, {payer: this.paymentService.getCls("unregistered")});
                    break;
            }
        }

        resetPayment() {
            const resetSuccess = this.entry ? () => this.paymentService.refreshEntry(this.entry.id) : _.noop;

            if (!_.isNil(this.visit.delivery_document)) this.paymentService.invalidateDeliveryDocument(this.visit.delivery_document)
                .then(() => {
                    this.paymentService.resetVisitPayments(this.visit)
                        .then(data => {
                            this.resetForm(data);
                            resetSuccess();
                        });
                });
            else this.paymentService.resetVisitPayments(this.visit)
                .then(data => {
                    this.resetForm(data);
                    resetSuccess();
                });
        }

        resetForm(data) {
            this.visit = data;

            this.payment = {
                payer: {
                    _module: "patient.models",
                    _model: "Patient",
                    id: data.patient_id
                },
                payer_type: "P",
                payee: {
                    _module: "patient.models",
                    _model: "Patient",
                    id: data.patient_id
                },
                payee_type: "P",
            };
            this.handlePayments([]);
        }

        addPayment() {
            this.newPayment = true;
            this.resetForm(this.visit);
        }

        billingVisit(save = false) {
            let billingVisitData = _.assign({}, this.visit, {patient: {id: this.visit.patient_id}});

            if (save) return this.submit().then(() => this.billingService.billingVisit(billingVisitData));
            else {
                this.dialog.hide();
                return this.billingService.billingVisit(billingVisitData);
            }
        }

        applyDiscount(discountType, visit, payment) {
            this.paymentService.applyDiscount(discountType, visit, payment);

            if (payment.organization_affectation) this.applyAffectation(discountType, visit, payment.organization_affectation)
        }

        applyAffectation(fieldType, visit, affectation) {
            if (fieldType === "value") {
                const affected_amount = parseFloat(_.get(affectation, "affected_amount", 0));

                if (affected_amount > 0) {
                    this.payment.amount = parseFloat(_.get(visit, "financial_status.remaining_amount", 0)) - affected_amount;
                } else {
                    this.payment.amount = this.amountMaximum;
                }
                this.paymentService.applyAffectation(fieldType, visit, affectation);
            } else {
                const remaining_amount = parseFloat(_.get(visit, "financial_status.remaining_amount", 0));
                this.paymentService.applyAffectation(fieldType, visit, affectation, remaining_amount);

                const affected_amount = parseFloat(_.get(affectation, "affected_amount", 0));
                this.payment.amount = this.payment.total - affected_amount;
            }
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        controller: EntryPaymentDialogCtrl,
        template: require("payment/views/entry-payment.tpl.html"),
        controllerAs: "vm",
        bindToController: true,
        multiple: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        onComplete: ($scope, $element) => {
            //$element.find(".procedures-container").removeClass("hidden")
        }
    };
})();
