/**
 * Created by amine on 16/02/2017.
 */
(function () {
    'use strict';

    const {BehaviorSubject} = require("rxjs");
    let $_currentPatient = null;

    class FinancialStatementContainerCtrl {
        constructor($state, $transition$, $translate, paymentService, patientService, visitService, configService, $scope, $mdToast) {
            this.state = $state;
            this.toast = $mdToast;
            this.paymentService = paymentService;
            this.patientService = patientService;
            this.visitService = visitService;
            this.configService = configService;
            this.scope = $scope;

            this.accountSubject = new BehaviorSubject(null);
            this.isDental = configService.isDental();
            this.hasMedicalCare = configService.hasMedicalCare();

            this.patient = null;
            this.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            };

            this.columns = [];
            this.options = [20, 15, 10, 5];

            this.selectedVisit = null;
            this.visitPromise = null;
            this.visits = [];
            this.visitTotal = 0;
            this.visitQuery = {
                limit: 10,
                page: 1,
                order: "-visit_date",
                hide_paid: false,
                hide_no_amount: true,
                is_exempt: true
            };
            this.visitSummary = {
                gross_total: 0,
                paid_amount: 0,
                global_discount: 0,
                payment_status: 0,
                remaining_amount: 0
            };


            this.selectedMedicalPlan = null;
            this.medicalPlanPromise = null;
            this.medicalPlans = [];
            this.medicalPlanTotal = 0;
            this.medicalPlanQuery = {
                limit: 10,
                page: 1,
                order: "-creation_date",
                hide_paid: false,
                hide_no_amount: true,
                is_exempt: true
            };
            this.medicalPlanSummary = {
                gross_total: 0,
                paid_amount: 0,
                global_discount: 0,
                payment_status: 0,
                remaining_amount: 0
            };


            this.selectedDental = null;
            this.dentalPromise = null;
            this.dental_plans = [];
            this.dentalTotal = 0;
            this.dentalQuery = {
                limit: 10,
                page: 1,
                order: "-creation_date",
                hide_paid: false,
                hide_no_amount: true,
                is_exempt: true
            };
            this.dentalSummary = {
                gross_total: 0,
                paid_amount: 0,
                global_discount: 0,
                payment_status: 0,
                remaining_amount: 0
            };


            this.selectedEncasement = null;
            this.encasementPromise = null;
            this.encasements = [];
            this.encasementTotal = 0;
            this.encasementQuery = {
                limit: 10,
                page: 1,
                order: "-encasement_date",
                start_date: null,
                end_date: null,
                payment_mode: null,
                physician: null
            };
            this.encasementSummary = {
                total_amount: 0,
                consumed_amount: 0,
                remaining_amount: 0
            };

            this.balance = 0;
            this.patientContainer = !_.startsWith($state.$current.name, "app.visit");

            this.currentPatient = _.get($transition$.params('to'), this.patientContainer ? "id" : "pId");
            this.selectedTab = _.get($transition$.params('to'), 'tab');
            this.origin = _.get($transition$.params('to'), 'origin');

            this.visitProceduresShown = false;
        }

        static get $inject() {
            return ["$state", "$transition$", "$translate", "paymentService", "patientService", "visitService", "configService", "$scope", "$mdToast"];
        }

        $onInit() {
            if (this.patientContainer) this.patientService.getMinimalPatient(this.currentPatient)
                .then(data => this.handlePatient(data), () => this.goBack(true));
            else $_currentPatient = this.visitService.currentPatientSubject
                .subscribe(data => this.handlePatient(data));

            this._getVisitData();
            this._getMedicalPlanData();
            this._getDentalData();
            this._getEncasementData();

            this.getVisitData = _.mnDelay(() => this._getVisitData(), 500);
            this.getMedicalPlanData = _.mnDelay(() => this._getMedicalPlanData(), 500);
            this.getDentalData = _.mnDelay(() => this._getDentalData(), 500);
            this.getEncasementData = _.mnDelay(() => this._getEncasementData(), 500);

            this.onVisitReorder = (order) => this._onVisitReorder(order);
            this.onVisitPaginate = (page, limit) => this._onVisitPaginate(page, limit);
            this.onEncasementReorder = (order) => this._onEncasementReorder(order);
            this.onEncasementPaginate = (page, limit) => this._onEncasementPaginate(page, limit);
            this.onDentalReorder = (order) => this._onDentalReorder(order);
            this.onDentalPaginate = (page, limit) => this._onDentalPaginate(page, limit);

            this.scope.$on("$destroy", () => this.onDestroy());
        }

        onDestroy() {
            if (!_.isNull($_currentPatient)) $_currentPatient.unsubscribe();
        }

        handlePatient(data) {
            this.patient = data;
            this.accountSubject.next(this.currentPatient);
        }

        _onVisitReorder(order) {
            this.visitQuery = _.assign(this.visitQuery, {order: order});
            this._getVisitData();
        }

        _onVisitPaginate(page, limit) {
            this.visitQuery = _.assign(this.visitQuery, {page: page, limit: limit});
            this._getVisitData();
        }

        _getVisitData() {
            this.visitPromise = this.paymentService
                .getVisits(_.assign(this.visitQuery, {patient: this.currentPatient}))
                .then(data => {
                    this.selectedVisit = null;
                    this.visitTotal = data.length;
                    this.visits = data.list;
                    this.visitSummary = _.assign(this.visitSummary, {
                        gross_total: data.gross_total,
                        paid_amount: data.paid_amount,
                        global_discount: data.global_discount,
                        payment_status: data.payment_status,
                        remaining_amount: data.remaining_amount,
                        organization_affected_amount: data.organization_affected_amount
                    });

                    if (this.visitTotal < ((this.visitQuery.page - 1) * this.visitQuery.limit)) {
                        this.visitQuery.page = 1;
                    }

                    this.accountSubject.next(this.currentPatient);
                });
        }

        _onMedicalPlanReorder(order) {
            this.medicalPlanQuery = _.assign(this.medicalPlanQuery, {order: order});
            this._getMedicalPlanData();
        }

        _onMedicalPlanPaginate(page, limit) {
            this.medicalPlanQuery = _.assign(this.medicalPlanQuery, {page: page, limit: limit});
            this._getMedicalPlanData();
        }

        _getMedicalPlanData() {
            this.medicalPlanPromise = this.paymentService
                .getMedicalPlan(_.assign(this.medicalPlanQuery, {patient: this.currentPatient}))
                .then(data => {
                    this.selectedMedicalPlan = null;
                    this.medicalPlanTotal = data.length;
                    this.medicalPlans = data.list;
                    this.medicalPlanSummary = _.assign(this.medicalPlanSummary, {
                        gross_total: data.gross_total,
                        paid_amount: data.paid_amount,
                        global_discount: data.global_discount,
                        payment_status: data.payment_status,
                        remaining_amount: data.remaining_amount,
                        organization_affected_amount: data.organization_affected_amount
                    });

                    if (this.medicalPlanTotal < ((this.medicalPlanQuery.page - 1) * this.medicalPlanQuery.limit)) {
                        this.medicalPlanQuery.page = 1;
                    }

                    this.accountSubject.next(this.currentPatient);
                });
        }

        _onEncasementReorder(order) {
            this.encasementQuery = _.assign(this.encasementQuery, {order: order});
            this._getEncasementData();
        }

        _onEncasementPaginate(page, limit) {
            this.encasementQuery = _.assign(this.encasementQuery, {page: page, limit: limit});
            this._getEncasementData();
        }

        _getEncasementData() {
            this.scope.$applyAsync(() => {
                this.encasementPromise = this.paymentService
                    .getEncasements(_.assign(this.encasementQuery, {patient: this.currentPatient}))
                    .then(data => {
                        this.selectedEncasement = null;
                        this.encasementTotal = data.length;
                        this.encasements = data.list;
                        this.encasementSummary = _.assign(this.encasementSummary, {
                            total_amount: data.total_amount,
                            consumed_amount: data.consumed_amount,
                            remaining_amount: data.remaining_amount
                        });

                        if (this.encasementTotal < ((this.encasementQuery.page - 1) * this.encasementQuery.limit)) {
                            this.encasementQuery.page = 1;
                        }

                        this.accountSubject.next(this.currentPatient);
                    });
            })
        }

        _onDentalReorder(order) {
            this.dentalQuery = _.assign(this.dentalQuery, {order: order});
            this._getDentalData();
        }

        _onDentalPaginate(page, limit) {
            this.dentalQuery = _.assign(this.dentalQuery, {page: page, limit: limit});
            this._getDentalData();
        }

        _getDentalData() {
            this.dentalPromise = this.paymentService
                .getDentalTreatmentPlan(_.assign(this.dentalQuery, {patient: this.currentPatient}))
                .then(data => {
                    this.selectedDental = null;
                    this.dentalTotal = data.length;
                    this.dental_plans = data.list;
                    this.dentalSummary = _.assign(this.dentalSummary, {
                        gross_total: data.gross_total,
                        paid_amount: data.paid_amount,
                        global_discount: data.global_discount,
                        payment_status: data.payment_status,
                        remaining_amount: data.remaining_amount,
                        organization_affected_amount: data.organization_affected_amount
                    });

                    if (this.dentalTotal < ((this.dentalQuery.page - 1) * this.dentalQuery.limit)) {
                        this.dentalQuery.page = 1;
                    }

                    this.accountSubject.next(this.currentPatient);
                });

        }

        payVisit($event, visit) {
            this.paymentService.payVisit($event, visit)
                .then(() => {
                    this._getVisitData();
                    this._getEncasementData();
                }, () => {
                    this._getVisitData();
                    this._getEncasementData();
                });
        }

        closeVisit($event, visit) {
            this.paymentService.closeVisit($event, visit)
                .then(() => this._getVisitData(), _.noop);
        }

        reopenVisit($event, visit) {
            this.paymentService.reopenVisit($event, visit)
                .then(() => this._getVisitData(), _.noop);
        }

        showProcedures(visit) {
            if (this.selectedVisit === visit.id) {
                this.selectedVisit = -1;
                this.visitProceduresShown = false;
            } else {
                this.selectedVisit = visit.id;
                this.visitProceduresShown = true;
            }
        }

        showPaymentDetails(visit) {
            this.visitProceduresShown = false;
            if (this.selectedVisit === visit.id) {
                this.selectedVisit = -1;
            } else {
                this.selectedVisit = visit.id;
            }
        }

        payMedicalPlan($event, plan) {
            this.paymentService.payMedicalPlan($event, null, plan)
                .then(() => {
                    this._getMedicalPlanData();
                    this._getEncasementData();
                }, () => {
                    this._getMedicalPlanData();
                    this._getEncasementData();
                });
        }

        closeMedicalPlan($event, plan) {
            return this.paymentService.closeMedicalPlan($event, plan, true)
                .then(() => this._getMedicalPlanData(), _.noop);
        }

        reopenMedicalPlan($event, plan) {
            return this.paymentService.reopenMedicalPlan($event, plan, true)
                .then(() => this._getMedicalPlanData(), _.noop);
        }

        payDentalTreatmentPlan($event, plan) {
            this.paymentService.payDentalTreatmentPlan($event, null, plan)
                .then(() => this._getDentalData(), () => this._getDentalData());
        }

        closeDentalTreatmentPlan($event, plan) {
            this.paymentService.closeDentalTreatmentPlan($event, plan)
                .then(() => this._getDentalData(), _.noop);
        }

        reopenDentalTreatmentPlan($event, plan) {
            this.paymentService.reopenDentalTreatmentPlan($event, plan)
                .then(() => this._getDentalData(), _.noop);
        }

        showTeethProcedures(plan) {
            if (this.selectedDental === plan.id) {
                this.selectedDental = -1;
                this.planProceduresShown = false;
            } else {
                this.selectedDental = plan.id;
                this.planProceduresShown = true;
            }
        }

        showTreatmentPlanPaymentDetails(plan) {
            this.visitProceduresShown = false;
            if (this.selectedDental === plan.id) {
                this.selectedDental = -1;
            } else {
                this.selectedDental = plan.id;
            }
        }

        showEncasementDetails(encasement) {
            if (this.selectedEncasement === encasement.id) {
                this.selectedEncasement = -1;
            } else {
                this.selectedEncasement = encasement.id;
            }
        }

        filtersChange() {
            if (this.visitQuery.hide_paid) this.visitQuery.hide_no_amount = true;
            this._getVisitData();
        }

        goBack(error = false) {
            if (error) this.state.go("app.patient", {});
            else if (this.origin === "payment") this.state.go("app.payment.main", {});
            else this.state.go("app.patient-form", {'patient_id': this.patient.id});

            return false;
        }

        refreshPatientFinancialStatus() {
            const promise = this.patientService.refreshFinancialStatus(this.currentPatient)
                .then(() => {
                    this._getVisitData();
                    this._getMedicalPlanData();
                    this._getDentalData();
                    this._getEncasementData();
                })

            const toast = this.toast.mnAdvancedToast()
                .handle(promise)
                .label('payment_financial_statement_fix');

            this.toast.show(toast);

            return promise
        }

    }

    module.exports = FinancialStatementContainerCtrl;
})();
