(function () {
    'use strict';
    const COMPARE_OPERATORS = require('parameters/json/convention-operators.json');
    const EXCEPTIONS_CONFIG = require('parameters/json/convention-exception-config.json');

    class ConventionsSetupCtrl {
        constructor($state, $mdToast, $translate, conventionService, $transition$, $q) {
            this.state = $state;
            this.$mdToast = $mdToast;
            this.$translate = $translate;
            this.conventionService = conventionService;
            this.$q = $q;
            this.currentConvention = _.get($transition$.params('to'), 'pk');
        }

        static get $inject() {
            return ["$state", "$mdToast", "$translate", "conventionService", "$transition$", "$q"];
        }

        $onInit() {
            this.defaultModel = {
                is_activated:true,
                exceptions: [{
                    fixed_price_mode: false,
                    tp: null,
                    tm: null,
                    tp_max_amount: null,
                    tp_amount: null,
                    tm_amount: null,
                    rules: [{rule_category: null}]
                }]
            };
            this.operators = COMPARE_OPERATORS;
            this.logicalOperators = [{"js": "&&", "py": "$and", "label": "and"}, {
                "js": "||",
                "py": "$or",
                "label": "or"
            }];
            this.exceptionsConfig = EXCEPTIONS_CONFIG;
            this.rule_categories = ['patient', 'procedures', 'general_stock_config'];
            this.category = null;
            this.conventions = [];
            this.hiddenExceptions = [];

            let promises = [this.conventionService.getConventionsList()];
            this.promise = null;

            if (this.currentConvention) {
                promises.push(this.conventionService.getConvention(this.currentConvention));
            }
            this.promise = this.$q.all(promises).then(data => {
                this.conventions = data[0];
                this.model = this.currentConvention ? data[1] : this.defaultModel;
            });
        }

        addNew() {
            this.currentConvention = null;
            this.editState(null);
            this.model = _.cloneDeep(this.defaultModel);
        }

        viewItem(item) {
            this.editState(item);
            this.model = _.cloneDeep(item);
        }

        editState(item) {
            this.state.go("app.parameters.conventions", {
                pk: _.get(item, 'id', null)
            }, {
                inherit: true
            }).then(_.noop);
        }

        remove() {

        }

        submit() {
            this.conventionService.saveConvention(this.model).then((data) => {
                this.model = data;
                let idx = _.findIndex(this.conventions, {id: _.get(data, 'id')});
                if (idx === -1) {
                    this.conventions.push(data);
                    this.currentConvention = data.id;
                } else {
                    this.conventions[idx] = data;
                }
                this.editState(data);

                this.$mdToast.show(this.$mdToast.simple()
                    .textContent(this.$translate.instant('save_success'))
                    .position("bottom left")
                    .hideDelay(3000));
            });
        }

        newException() {
            this.model.exceptions.push({
                fixed_price_mode: false,
                tp: null,
                tm: null,
                tp_max_amount: null,
                tm_amount: null,
                tp_amount: null,
                rules: [{rule_category: null}]
            });
        }

        getOperators(key) {
            return key ? _.chain(this.operators).find({type: key['type']}).get('operators').value() : [];
        }

        handleKeyChange(rule, key) {
            rule['inner_operator'] = _.first(this.getOperators(key));
        }

        addRule(exception) {
            exception.rules[exception.rules.length - 1]['outer_operator'] = _.first(this.logicalOperators);
            exception.rules.push({rule_category: null});
        }

        removeException(idx) {
            this.model.exceptions.splice(idx, 1);
        }

        deleteRule(exception, idx) {
            exception?.rules.splice(idx, 1);
        }

        handlePriceModeChange(exception) {
            if (exception.fixed_price_mode) {
                exception.tm_amount = null;
                exception.tp_amount = null;
            }
            if (!exception.fixed_price_mode) {
                exception.tm = null;
                exception.tp = null;
            }
        }
    }

    module.exports = ConventionsSetupCtrl;

})()