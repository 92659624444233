(function () {

    'use strict';

    class PrismaDevicesListCtrl {
        constructor($mdDialog, CloudPlatformsService, $q, $translate) {
            this.dialog = $mdDialog;
            this.CloudPlatformsService = CloudPlatformsService;
            this.q = $q;
            this.translate = $translate;
        }

        static get $inject() {
            return ["$mdDialog", "CloudPlatformsService", "$q", "$translate"];
        }

        $onInit() {
            this.devices = [];
            this.assignedDevices = [];
            this.loadDevices();
        }

        loadDevices() {
            this.promise = this.q.all([this.CloudPlatformsService.getAssignedDevices(), this.CloudPlatformsService.getDevices()])
                .then(data => {
                    this.assignedDevices = data[0];
                    this.devices = data[1];
                    this.promise = this.q.all(_.map(this.devices, (d) => {
                        return this.CloudPlatformsService.getPatientWithSn(d.deviceSn)
                    })).then(res => {
                        for (let index = 0; index < this.devices.length; index++) {
                           this.devices[index] = _.assignIn(res[index], this.devices[index]);
                        }
                    });
                });
        }

        cancel() {
            this.dialog.cancel();
        }

        rejectDevice(p) {
            this.promise = this.CloudPlatformsService.unAssignDevice(_.get(p, 'deviceSn'), _.get(p, 'id') ,_.get(this.getLocalAffectation(_.get(p, 'id')), 'patient'))
                .then(data => {
                    if (!_.get(data, 'error')) {
                        this.loadDevices();
                        this.dialog.show(
                            this.dialog.alert()
                                .parent($(document.body))
                                .clickOutsideToClose(true)
                                .title('')
                                .textContent(this.translate.instant(!_.get(data, 'error') ? "reject_device_success" : "reject_device_error"))
                                .ariaLabel(_.get(data, 'error'))
                                .ok(this.translate.instant('ok'))
                                .multiple(true)
                        );
                    }
                });
        }
        getLocalAffectation(externalId){
            return _.find(this.assignedDevices, {patient_external_id:externalId});
        }
    }



        module.exports = {
        template: require("../views/prisma-devices-list.tpl.html"),
        bindings: {
        },
        controllerAs: "vm",
        controller: PrismaDevicesListCtrl
    }

})();
