(function () {
    "use strict";

    module.exports = ArGenerator;

    function ArGenerator(generator, $translate) {
        let self = this;
        self.generator = generator;

        self.formulaParser = formulaParser;
        self.nextLine = nextLine;

        function formulaParser(options) {
            let formula = _.get(self.generator, "formula", "");
            const partQuantities = {
                "0.20": "1/5",
                "0.25": "1/4",
                "0.34": "1/3",
                "0.33": "1/3",
                "0.40": "2/5",
                "0.50": "1/2",
                "0.60": "3/5",
                "0.66": "2/3",
                "0.67": "2/3",
                "0.75": "3/4",
                "0.80": "4/5"
            };
            const frequencies = getFrequencies();

            let lets = {
                "[quantity]": getQuantity(),
                "[unit]": getUnit(),
                "[qte_unit]": getQuantityUnitPosology(),
                "[frequencies]": _.isEmpty(frequencies) ? "" : frequencies,
                "[period]": getPeriod()
            };

            _.forEach(lets, function (v, k) {
                formula = _.replace(formula, k, v);
            });

            return formula.replace(/(,){2,}/g, ", ")
                .replace(/(\s){2,}/g, " ")
                .replace(/(,\s|\s,){2,}/g, ", ")
                .replace(/(,\s,)/g, ", ");

            function getQuantity() {
                let value = _.get(options, 'quantity', "1");
                let partQuantity = partQuantities[parseFloat(value).toFixed(2)];

                return _.isNil(partQuantity) ? value : partQuantity;
            }

            function getFrequencies() {
                return _.reduce(options.frequencies, function (result, item) {
                    let value = _.get(item, "ar_value", "");

                    if (!_.isEmpty(value)) return _.concat(result, value);
                    else return result;
                }, []).join(", ")
            }

            function getUnit() {
                let real_quantity = _.get(options, 'quantity', 1)
                let real_unit = _.get(options, 'unit.ar_instance');

                let value = _.get(real_unit, "value", "");
                let dual_value = _.get(real_unit, "dual_value", value);
                let plural_value = _.get(real_unit, "plural_value", value);

                if (real_quantity === 2 && !_.isNil(dual_value)) return dual_value;
                if (real_quantity > 2 && real_quantity < 11 && !_.isNil(plural_value)) return plural_value;

                return value;
            }

            function getQuantityUnitPosology() {
                let real_quantity = _.get(options, 'quantity', 1);

                if (real_quantity < 1 || real_quantity > 2) {
                    return `${getQuantity()} ${getUnit()}`;
                } else {
                    return getUnit();
                }
            }

            function getPeriod() {
                let prefix = $translate["instant"](_.get(self.generator, "period_prefix", ""))

                let type = options.period.type;
                let length = options.period.quantity;

                if (length < 1) return "";

                let type_t = type + "_ar";
                if (length === 2) {
                    type_t = $translate['instant'](type_t + "_dual");
                } else if (length > 2 && length < 11) {
                    type_t = $translate['instant'](type_t + "_plural");
                } else {
                    type_t = $translate['instant'](type_t);
                }

                return `,${prefix} ${length < 3 ? "" : length} ${type_t}`;
            }
        }

        function nextLine(text) {
            const newLine = $translate["instant"](_.get(self.generator, "new_line", ""));

            return `${text}\n${_.toLower(newLine)}`;
        }
    }

})();