(function () {

    'use strict';

    class AppBackupDialogCtrl {
        constructor($mdDialog, $timeout) {
            this.dialog = $mdDialog;
            this.timeout = $timeout;

            this.waiting = true;
        }

        static get $inject() {
            return ["$mdDialog", "$timeout"];
        }

        $onInit() {
            this.timeout(() => this.waiting = false, 30 * 1000)
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: AppBackupDialogCtrl,
        template: require("./app-backup-error-dialog.tpl.html"),
    };


})();