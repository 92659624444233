(function () {

    'use strict';


    class FormsTemplatesSetupCtrl {
        constructor($transition$, $state, $q, $mdDialog, system, $translate, formsService, $mdToast, $scope, dragulaService, measureService) {
            this.$q = $q;
            this.dialog = $mdDialog;
            this.$translate = $translate;
            this.formsService = formsService;
            this.state = $state;
            this.$mdToast = $mdToast;
            this.scope = $scope;
            this.dragulaService = dragulaService;
            this.measureService = measureService;
            this.currentTemplate = _.get($transition$.params('to'), 'template');
        }

        static get $inject() {
            return ["$transition$", "$state", "$q", "$mdDialog", "system", "$translate", "formsService", "$mdToast", "$scope", "dragulaService", "measureService"];
        }

        $onInit() {
            this.hiddenBlocks = {};
            this.measurements = [];
            this.defaultInit();
            this.bag = "fields-bag";
            this.initDrag();
        }

        defaultInit() {

            this.formsTemplates = [];
            this.fieldTypes = ["checkbox", "radio-button", "text", "long-text", "number",
                "date", "title", "paragraph", "checkbox-table", "list", "image", "table-layout", "formula"];
            this.defaultField = {
                type: 'checkbox',
                name: `${this.$translate.instant("field_name")}_1`,
                label: '',
                options: ['Option1'],
                required: false,
                config: {}
            };
            this.defaultBlock = {
                name: `${this.$translate.instant("block_name")}_1`,
                label: null,
                fields: [],
                config: {}
            };
            // this.formTemplate = _.cloneDeep({blocks: [_.assignIn(this.defaultBlock, {fields: [_.cloneDeep(this.defaultField)]})]});
            let promises = [this.formsService.getFormsTemplates(), this.measureService.getMeasures(false)];

            if (this.currentTemplate) {
                promises.push(this.formsService.getFormsTemplates(this.currentTemplate));
            }
            this.promise = this.$q.all(promises).then(data => {
                this.formsTemplates = data[0];
                this.measurements = data[1];
                this.formTemplate = this.currentTemplate ? data[2] : _.cloneDeep({blocks: [_.assignIn(this.defaultBlock, {fields: [_.cloneDeep(this.defaultField)]})]});
            });
        }

        initDrag() {
            this.dragulaService.options(this.scope, this.bag, {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });
            const drake = this.dragulaService.find(this.scope, this.bag).drake;
            drake.on("dragend", _.mnDelay(this.moveField, 400));
        }

        moveField() {
        }

        viewTemplate(item) {
            this.editState(item);
            this.currentTemplate = item.id;
            this.formTemplate = _.cloneDeep(item);
        }

        saveFormTemplate() {
            return this.formsService.saveFormTemplate(this.formTemplate).then(data => this.afterSave(data));
        }

        afterSave(data) {
            this.formTemplate = data;
            let idx = _.findIndex(this.formsTemplates, {id: _.get(data, 'id')});
            if (idx === -1) {
                this.formsTemplates.push(data);
                this.currentTemplate = data.id;
            } else {
                this.formsTemplates[idx] = data;
            }
            this.formsService.updateTemplates(this.formsTemplates);
            this.editState(data);

            this.$mdToast.show(this.$mdToast.simple()
                .textContent(this.$translate.instant('save_template_success'))
                .position("bottom left")
                .hideDelay(3000));

            return data;
        }

        addItem() {
            this.currentTemplate = null;
            this.formTemplate = _.cloneDeep({blocks: [_.assignIn(this.defaultBlock, {fields: [_.cloneDeep(this.defaultField)]})]});
            this.editState(null);
        }

        editState(item) {
            let params = {
                template: _.get(item, 'id', null)
            }
            this.state.go("app.parameters.forms-templates", params, {
                inherit: true
            }).then(_.noop);
        }

        addOption(f, type) {
            switch (f.type) {
                case 'checkbox':
                case 'radio-button':
                case 'list' :
                    f.options.push(`${this.$translate.instant('option')}${_.size(f.options) + 1}`);
                    break;
                case 'checkbox-table' :
                case 'table-layout' :
                    if (type === 'columns') {
                        f.options.columns.push(`${this.$translate.instant('column')}${_.size(f.options.columns) + 1}`)
                    } else {
                        f.options.rows.push(`${this.$translate.instant('row')}${_.size(f.options.rows) + 1}`);
                    }
                    break;
            }
        }

        fieldTypeChange(f) {
            switch (f.type) {
                case 'date' :
                case 'text' :
                case 'long-text' :
                    f.options = {is_measurement: false, measurement: null};
                    break;
                case 'list' :
                case 'checkbox':
                case  'radio-button':
                    f.options = ["Option1"];
                    break;
                case 'number' :
                    f.options = {decimals: 0, is_measurement: false, measurement: null};
                    break;
                case 'image' :
                    f.config = {picture_style: {height: '100%', width: '100%', 'margin-left': 'auto'}};
                    break;
                case 'checkbox-table':
                case 'table-layout':
                    f.options = {rows: [], columns: [], rowsLabel: ''};
                    break;
                case 'formula':
                    f.options = {formula: null};
                    break;
            }
        }

        removeOption(f, idx) {
            _.pullAt(f.options, idx);
        }

        deleteFormTemplate() {
            this.formsService.deleteFormTemplate(this.formTemplate.id).then(data => {
                _.remove(this.formsTemplates, {id: this.formTemplate.id});
                this.currentTemplate = null;
                // this.defaultInit();
                this.formTemplate = {blocks: [_.assignIn(this.defaultBlock, {fields: [_.cloneDeep(this.defaultField)]})]};
                this.editState(null);
            });
        }

        exportFormTemplate() {
            this.saveFormTemplate()
                .then(() => this.formsService.exportFormTemplate(this.formTemplate.id))
        }

        newField(b, idx = null) {
            if (!_.isNull(idx)) {
                b.fields.splice(idx + 1, 0, _.cloneDeep(_.assignIn(this.defaultField, {name: `${this.$translate.instant("field_name")}_${_.size(b.fields) + 1}`})));
            } else {
                b.fields.unshift(_.cloneDeep(_.assignIn(this.defaultField, {name: `${this.$translate.instant("field_name")}_${_.size(b.fields) + 1}`})))
            }
        }

        newBlock(b, f) {
            let idx = _.findIndex(this.formTemplate.blocks, {name: b.name});
            if (idx !== -1) {
                this.formTemplate.blocks.splice(idx + 1, 0, _.cloneDeep(_.assignIn(this.defaultBlock, {name: `${this.$translate.instant("block_name")}_${_.size(this.formTemplate.blocks) + 1}`})));
            } else {
                this.formTemplate.blocks.push(_.cloneDeep(_.assignIn(this.defaultBlock, {name: `${this.$translate.instant("block_name")}_${_.size(this.formTemplate.blocks) + 1}`})));
            }
        }

        removeBlock(b, idx) {
            this.formTemplate.blocks.splice(idx, 1);
        }

        removeField(b, f, idx) {
            b.fields.splice(idx, 1);
        }

        duplicateField(b, f, idx) {
            b.fields.splice(idx + 1, 0, _.cloneDeep(f));
        }

        duplicateBlock(b, idx) {
            this.formTemplate.blocks.splice(idx + 1, 0, _.cloneDeep(b));
        }

        removeOptionRow(r, idx) {
            r.splice(idx, 1);
        }

        removeOptionColumn(c, idx) {
            c.splice(idx, 1);
        }

        checkFormUniqueness(value, c_name, data, key) {
            this.scope.Form[c_name].$setValidity('unique', this.formTemplate.id ? !_.chain(data).filter((e) => {
                return e[key] === value && e.id !== this.formTemplate.id
            }).size().value() > 0 : !_.chain(data).filter((e) => {
                return e[key] === value
            }).size().value() > 0);
        }

        checkNameUniqueness(value, c_name, data) {
            if (_.chain(data).filter({name: value}).size().value() > 1) {
                this.scope.Form[c_name].$setValidity('unique', false);
            } else {
                this.scope.Form[c_name].$setValidity('unique', true);
            }
        }

        optionChanged(o, options, idx) {
            options[idx] = o;
        }

        resetPicture(f, ev) {
            ev.stopPropagation();
            f.picture = null;
        }

        addPictureField(b, files, idx = null) {

            if (!_.isNull(idx)) {
                b.fields.splice(idx + 1, 0, _.assignIn(_.cloneDeep(this.defaultField), {
                    type: "image",
                    name: `${this.$translate.instant("field_name")}_${_.size(b.fields) + 1}`,
                    config: {picture_style: {height: '100%', width: '100%', 'margin-left': 'auto'}}
                }));
            } else {
                b.fields.unshift(_.assignIn(_.cloneDeep(this.defaultField), {
                    type: "image",
                    name: `${this.$translate.instant("field_name")}_${_.size(b.fields) + 1}`,
                    config: {picture_style: {height: '100%', width: '100%', 'margin-left': 'auto'}}
                }))
            }

            this.promise = this.convertToBase64(files[0]).then(data => {
                if (!_.isNull(idx)) {
                    b.fields[idx + 1].picture = data;
                } else {
                    b.fields[b.fields.length].picture = data
                }
            })
        }

        convertToBase64(file) {
            return new Promise((resolve, reject) => {
                const r = new FileReader();
                r.readAsDataURL(file);
                r.onload = () => resolve(r.result);
                r.onerror = error => reject(error);
            });
        }

        editPictureField(f, files) {
            this.promise = this.convertToBase64(files[0]).then(data => {
                f.picture = data;
            });
        }

        addFieldImage(b, f, idx, files) {
            this.promise = this.convertToBase64(files[0]).then(data => {
                if (!_.isNull(idx)) {
                    f.picture = data;
                } else {
                    f.picture = data
                }
                if (!_.has(f.config, 'picture_style')) {
                    f.config.picture_style = {height: '100%', width: '100%', 'margin-left': 'auto'};
                }
            })
        }

        addTableLayoutRow(f) {
            f.options.rows.push({
                name: `${this.$translate.instant('row_item')}_${_.size(f.options.rows) + 1}`,
                items: [{
                    type: 'checkbox',
                    name: `${this.$translate.instant('row_item')}_${_.size(f.options.rows) + 1}_${this.$translate.instant('item')}_${1}`,
                    options: []
                }]
            });
        }

        addRowElement(r) {
            r.items.push({
                type: 'checkbox',
                name: `${r.name}_${this.$translate.instant('item')}_${_.size(r.items) + 1}`,
                options: []
            });
        }

        removeRowItem(items, idx) {
            items.splice(idx, 1)
        }

        handleBlockStyle(ev) {
        }

        importFormTemplate(files) {
            let reader = new FileReader();
            reader.onloadend = function (evt) {
                if (evt.target.readyState === FileReader.DONE) {
                    this.currentTemplate = null;
                    this.formTemplate = _.cloneDeep(_.omit(JSON.parse(evt.target.result), 'id'));
                    this.editState(null);
                }
            }.bind(this);
            reader.readAsText(files[0]);
        }

        duplicateTemplate(ev) {
            this.currentTemplate = null;
            this.formTemplate = _.cloneDeep(_.assignIn(_.omit(this.formTemplate, 'id'),
                {name: "DUP_" + _.get(this.formTemplate, 'name'), label: "DUP_" + _.get(this.formTemplate, 'label')}));
            this.editState(null);
        }

        templateAsHtmlBlock(ev) {
            this.saveFormTemplate()
                .then(() => {
                    this.formsService.templateAsHtmlBlock(this.formTemplate.id);
                });
        }
    }

    module.exports = FormsTemplatesSetupCtrl;
})();
