/**
 * Created by amine on 06/12/2018.
 */
(function () {
        'use strict';

        const bag = "field-bag";

        class ObservationSetupCtrl {
            constructor(consultationService, observationService, listService, blockService, $state, $q, configService, $mdToast, $translate, dragulaService, $scope, medicalFileBlockUID) {
                this.consultationService = consultationService;
                this.observationService = observationService;
                this.listService = listService;
                this.blockService = blockService;
                this.$state = $state;
                this.$q = $q;
                this.configService = configService;
                this.$mdToast = $mdToast;
                this.$translate = $translate;
                this.dragulaService = dragulaService;
                this.$scope = $scope;
                this.medicalFileBlockUID = medicalFileBlockUID;

                this.selectTab = null;
                this.observationEntryTypes = [];
                this.observationIndications = [];
                this.promise = null;
            }

            static get $inject() {
                return [
                    "consultationService", "observationService", "listService", "blockService", "$state",
                    "$q", "configService", "$mdToast", "$translate", "dragulaService", "$scope", "medicalFileBlockUID"
                ];
            }

            $onInit() {
                this.promise = this.$q.all([
                    this.listService.list("ObservationEntryType"),
                    this.listService.list("ObservationIndication"),
                    this.configService.get("observation_config", true),
                    this.blockService.get(this.medicalFileBlockUID)
                ]).then(data => {
                    this.observationEntryTypes = data[0];
                    this.observationIndications = data[1];
                    this.config = data[2];
                    this.summaryConfig = _.get(this.config, 'summary_config', []);

                    this.medicalFileFields = data[3]['html_fields'];
                    this.fieldTypes = _.chain(require('../json/summary-fields-type.json'))
                        .find(["source", "MedicalFile"])
                        .castArray()
                        .value();
                });

                this.dragulaService.options(this.$scope, bag, {
                    revertOnSpill: false,
                    moves: (el, container, handle) => {
                        return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                    }
                });
            }

            handleConfig() {
                return this.configService.set({"observation_config": this.config}, true)
                    .then(() => {
                        const simpleToast = this.$mdToast.simple()
                            .textContent(this.$translate['instant']('exam_setup_edit_success'))
                            .position("bottom left")
                            .hideDelay(1500);

                        setTimeout(() => {
                            this.$mdToast.show(simpleToast);
                        }, 100);
                    });
            }

            handleObservationEntryType(type, event) {
                switch (_.get(type, "cat")) {
                    case "report":
                        return this.handleReportAsType(type, event);
                    default:
                        return this.observationService.editEntryTypes(type, event)
                            .then(() => {
                                this.listService.list("ObservationEntryType")
                                    .then(data => {
                                        this.observationEntryTypes = data;
                                    }, _.noop);
                            }, _.noop);
                }
            }

            handleReportAsType(type, event) {
                return this.observationService.editReportEntryTypes(type, event)
                    .then(() => {
                        this.listService.list("ObservationEntryType")
                            .then(data => {
                                this.observationEntryTypes = data;
                            }, _.noop);
                    }, _.noop);

            }

            handleObservationIndication(indication, event) {
                return this.observationService.editIndication(indication, event)
                    .then(() => {
                        this.listService.list("ObservationIndication")
                            .then(data => {
                                this.observationIndications = data;
                            }, _.noop);
                    }, _.noop);
            }

            removeItem(item, event) {
                this.listService.removeItem(item, event)
                    .then(() => {
                        if (this.selectTab === "type") this.listService.list("ObservationEntryType")
                            .then(data => {
                                this.observationEntryTypes = data;
                            }, _.noop);

                        if (this.selectTab === "indication") this.listService.list("ObservationIndication")
                            .then(data => {
                                this.observationIndications = data;
                            }, _.noop);
                    });
            }

            addNewSummary() {
                this.summaryConfig.push({});
            }

            removeSummaryField(index) {
                this.summaryConfig.splice(index, 1);
            }

            handleSummaryType(summary) {
                if (summary.source === 'Exam' || summary.source === 'Prescription') {
                    _.assign(summary, {
                        type: "url-list",
                        label: this.$translate.instant(`summary_file_setup_${summary.source == 'Exam' ? 'exam' : 'prescription'}`),
                    });
                } else {
                    _.assign(summary, {type: "field"});
                }
            }

            assignSummaryLabel(summary) {
                const field = _.find(this.medicalFileFields, {key: summary['field_key']});

                summary = _.assign(summary, _.pick(field, 'label'), {
                    "value_key": field.type == "autocomplete" ? _.get(field, "meta_data.field_name") : "value"
                });

                return summary;
            }

            handleSummarySaving() {
                this.config['summary_config'] = this.summaryConfig;
                return this.handleConfig();
            }
        }

        module
            .exports = ObservationSetupCtrl;

    }

)();
