(function () {

    'use strict';

    class AppAlertDialogCtrl {
        constructor($mdDialog, $timeout, $sce) {
            this.dialog = $mdDialog;
            this.timeout = $timeout;
            this.sce = $sce;

            this.waiting = true;
            this.waitingTime = 30;
            this.title = "";
            this.content = "";
            this.options = {};
            this.switchValue = false;
            this.switchText = "";

            this.close = () => {
                this.dialog.hide(true);
            }
        }

        static get $inject() {
            return ["$mdDialog", "$timeout", "$sce"];
        }

        $onInit() {
            this.content = this.sce.trustAsHtml(this.content);
            this.timeout(() => this.waiting = false, this.waitingTime * 1000);

            this.waitingTime = _.get(this.options, "waitingTime", 30)
            this.action = _.get(this.options, "action")


            if (_.isFunction(this.action)) {
                this.actionLabel = _.get(this.options, "actionLabel", "")
            } else {
                this.action = false;
            }
        }

        switchToggled() {
            this.dialog.hide(true);
        }

        cancel() {
            this.dialog.cancel();
        }


    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: AppAlertDialogCtrl,
        template: require("./app-alert-dialog.tpl.html"),
    };


})();