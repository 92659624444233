/**
 * Created by BETALOS on 23/11/2016.
 */
(function () {

    'use strict';

    const DEVICE_DRIVER_FORM_DIALOG = require('../dialogs/device-driver-form/device-driver-form-dialog');

    class DeviceDriversCtrl {
        constructor(interfacingService, $transition$, $mdDialog, $state, $scope) {
            this.state = $state;
            this.scope = $scope;
            this.dialog = $mdDialog;
            this.$transition$ = $transition$;
            this.interfacingService = interfacingService;
        }

        static get $inject() {
            return ["interfacingService", "$transition$", "$mdDialog", "$state", "$scope"];
        }

        $onInit() {
            const params = this.$transition$.params('to');
            this.currentDevice = params.device;

            this.interfacingService.getDeviceDetail(this.currentDevice)
                .then(data => this.successCallback(data));

            const subscription = this.interfacingService.deviceConfigUpdates(this.currentDevice)
                .subscribe(data => this.successCallback(data));

            this.scope.$on("$destroy", () => {
                if (subscription) subscription.unsubscribe();
            })
        }

        successCallback(data) {
            this.device = data;
            this.drivers = data['device_active_configs'];
        }

        goBack() {
            this.state.go('app.parameters.devices');
        }

        handleDriver(driver, ev) {
            this.dialog.show(_.assign({}, DEVICE_DRIVER_FORM_DIALOG, {
                targetEvent: ev,
                locals: {
                    device: this.currentDevice,
                    driver: _.cloneDeep(driver)
                }
            }));
        }

        removeDriver(driver) {
            this.interfacingService.removeDeviceConfig(this.currentDevice, driver);
        }
    }

    module.exports = DeviceDriversCtrl;

})();
