/**
 * Created by BETALOS on 18/12/2015.
 */


(function () {

    'use strict';

    let mnVaccination = {
        controller: mnVaccinationCtrl,
        controllerAs: "vm",
        bindings: {
            patient: "<mnPatient",
            readonly: "<mnReadOnly"
        },
        template: require('patient/views/vaccination.tpl.html'), // or template
    };

    mnVaccinationCtrl.$inject = ["patientService", "$mdDialog", "system", "$element", "moment"];

    function mnVaccinationCtrl(patientService, $mdDialog, system, $elem, moment) {
        let vm = this;
        let dateFormat = system['date_format'].js;

        let initial = [];
        let dialog = {
            controller: VaccinationDialog,
            controllerAs: "vm",
            template: require("patient/views/vaccination-dialog.tpl.html"),
            parent: $(document.body),
            bindToController: true,
            clickOutsideToClose: false
        };

        vm.$onInit = init;
        vm.newVaccination = newVaccination;
        vm.edit = edit;
        vm.remove = remove;

        vm.onReorder = onReorder;

        function init() {
            vm.order = '-vaccination_date';
            vm.vaccinations = [];

            $elem
                .addClass("flex-noshrink")
                .addClass("layout-column");

            vm.promise = patientService.getVaccinations(vm.patient)
                .then(success, _.noop);

            function success(data) {
                initial = data;
                onReorder();
            }
        }

        function newVaccination(ev) {
            $mdDialog.show(_.assign(dialog, {
                targetEvent: ev,
                locals: {
                    patient: vm.patient
                }
            })).then(handleItem);
        }

        function edit(item, ev) {
            $mdDialog.show(_.assign(dialog, {
                targetEvent: ev,
                locals: {
                    patient: vm.patient,
                    vaccination: _.pick(item, ['id', 'vaccination_date', 'comment', 'vaccine_id'])
                }
            })).then(handleItem);

        }

        function handleItem(item) {
            initial = _.pushOrUpdate(initial, item);
            onReorder();
        }

        function remove(item) {
            patientService.removeVaccination(item.id)
                .then(removeVaccination)

            function removeVaccination() {
                _.remove(initial, {id: item.id});
                onReorder();
            }
        }

        function onReorder() {
            let items = _.sortBy(initial, handleSort);
            let orderDirection = !_.eq(vm.order.charAt(0), '-');

            vm.vaccinations = orderDirection ? items : items.reverse();

            function handleSort(item) {
                let orderKey = _.eq(vm.order.charAt(0), '-') ? vm.order.substring(1) : vm.order;
                let value = moment(item[orderKey], dateFormat);

                return value.isValid() ? value.valueOf() : item[orderKey];
            }
        }
    }

    VaccinationDialog.$inject = ["patientService", "$mdDialog", "system", "moment"];

    function VaccinationDialog(patientService, $mdDialog, system, moment) {
        let vm = this;
        let dateFormat = _.get(system, 'date_format.js');

        vm.vaccination = [];

        vm.$onInit = init;
        vm.submit = submit;
        vm.cancel = $mdDialog.cancel;

        function init() {
            vm.vaccination = !_.isNil(vm.vaccination) ? _.assign(vm.vaccination, {
                patient: {id: vm.patient},
                vaccine: {id: vm.vaccination['vaccine_id']}
            }) : {
                vaccination_date: moment().format(dateFormat),
                patient: {
                    id: vm.patient
                }
            }
        }

        function submit() {
            patientService.handleVaccination(vm.vaccination)
                .then($mdDialog.hide);
        }
    }

    module.exports = mnVaccination;

})();
