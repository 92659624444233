(function () {

    'use strict';

    class FormDialogCtrl {
        constructor($mdDialog, $http, operatorService) {
            this.http = $http;
            this.dialog = $mdDialog;
            this.operatorService = operatorService;
            this.model = this.model || null;
        }

        static get $inject() {
            return ["$mdDialog", "$http", "operatorService"];
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.operatorService.saveOperator(this.model)
                .then(data => this.dialog.hide(data));
        }
    }

    class OperatorSetupCtrl {
        constructor($mdDialog, operatorService) {
            this.operatorService = operatorService;
            this.dialog = $mdDialog;
        }

        static get $inject() {
            return ["$mdDialog", "operatorService"];
        }

        $onInit() {
            this.technicians = [];
            this.loadOperators();
        }

        loadOperators() {
            this.promise = this.operatorService.getOperators().then(data => {
                this.technicians = data;
            });
        }

        handleEdit(e) {
            const FORM_DIALOG = {
                locals: {model:_.cloneDeep(e)},
                multiple: true,
                controllerAs: "vm",
                bindToController: true,
                parent: $(document.body),
                clickOutsideToClose: true,
                controller: FormDialogCtrl,
                template: require('parameters/views/operator-edit-tpl.html'),
                targetEvent: null,
                focusOnOpen: false
            };

            this.dialog.show(FORM_DIALOG).then(data => {
                this.loadOperators();
            });
        }

        removeTechnician(e) {
            this.promise = this.operatorService.deleteOperator(e.id).then(data => {
                this.loadOperators();
            });
        }

    }

    module.exports = OperatorSetupCtrl;

})();
