/**
 * Created by Issmahane
 */
(function () {
    "use strict";

    module.exports = mnBiologyRow;

    mnBiologyRow.$inject = ["$compile", "system"];

    function mnBiologyRow($compile, system) {
        let precise_float = _.isNil(system.precise_float) ? 2 : system.precise_float;
        const directive = {
            restrict: 'A',
            scope: {
                item: "=mnBiologyRow",
                patientData: '<patientData',
                onDelete: "&onDelete",
                readOnly: "<",
                noRemove: "<",
                isFirst: "<",
                isResult: "=",
                isModel: "="
            },
            link: link
        };

        function link($scope, $element) {
            $scope.value = "";
            $scope.details = [];

            $scope.colspan = ($scope.isResult && $scope.isModel) ? 5 : ($scope.isResult && !$scope.isModel) ? 6 : 5;

            $scope.deleteItem = deleteItem;
            $scope.deleteDetail = deleteDetail;

            let unwatchPatient = $scope.$watch("patientData", patientWatch);

            function patientWatch(value) {
                if (_.isUndefined(value)) return false;

                if ($scope.item.exam['is_unique']) handleUnique();
                else $scope.$watchCollection("item", watchDetails);

                $scope.$watch("readOnly", watchDetails)

                $compile($element.contents())($scope);
                unwatchPatient();
            }

            function handleUnique() {
                let $tr = $(require('./details-unique.tpl.html'));

                let interval = _.find($scope.item.exam.settings.intervals, findInterval);

                if (!_.isUndefined(interval))
                    $("td:eq(1)", $tr).text(_.get(interval, 'value.min', 0) + ' - ' + _.get(interval, 'value.min', 0));
                let type = $scope.item.exam.settings.type;

                if (type === "boolean") {
                    /*$("td:eq(5)", $tr).append('<md-icon md-font-icon="mdi-checkbox-blank-circle" md-font-set="mdi" ' +
                     'aria-label="status"></md-icon>');
                     if (!$scope.readOnly) {
                     if (!_.isUndefined($scope.item.last_value)) {
                     let $icon = $('<md-icon />')
                     .attr("md-font-icon", $scope.item.last_value ? "mdi-checkbox-marked" : "mdi-checkbox-blank")
                     .attr("md-font-set", "mdi")
                     .attr("aria-label", "status");
                     }
                     }*/
                } else if (!_.isUndefined($scope.item.last_value)) {
                    $("td:eq(2)", $tr).attr("ng-bind", "item.last_value");
                }
                if (!$scope.readOnly) {
                    let td = $("td:eq(3) ", $tr);
                    let comment = $("td:eq(5)", $tr);
                    if ($scope.isFirst) td.attr('mn-auto-focus', "");

                    handleInput(type, td);
                    $("input, md-checkbox", td).attr("ng-model", "item.value");

                    handleInput(null, comment, "comment");
                    $("input", comment).attr("ng-model", "item.comments['unique']");
                } else {
                    if (type !== "boolean") {
                        $("td:eq(3)", $tr).text($scope.item.value);
                        if (!_.isNull($scope.item.last_value)) {
                            $("td:eq(2)", $tr).attr("ng-bind", "item.last_value");
                        }
                    } else {
                        if (!_.isNull($scope.item.last_value)) {
                            if ($scope.item.last_value) {
                                $("td:eq(2)", $tr).append('<md-icon md-font-icon="mdi-checkbox-marked" md-font-set="mdi" ' +
                                    'aria-label="status"></md-icon>');
                            } else {
                                $("td:eq(2)", $tr).append('<md-icon md-font-icon="mdi-checkbox-blank" md-font-set="mdi" ' +
                                    'aria-label="status"></md-icon>');
                            }
                        }
                        if ($scope.item.value) {
                            $("td:eq(3)", $tr).append('<md-icon md-font-icon="mdi-checkbox-marked" md-font-set="mdi" ' +
                                'aria-label="status"></md-icon>');
                        } else {
                            $("td:eq(3)", $tr).append('<md-icon md-font-icon="mdi-checkbox-blank" md-font-set="mdi" ' +
                                'aria-label="status"></md-icon>');
                        }
                    }

                    $("td:eq(5)", $tr).attr("ng-bind", `item.comments['unique']`);
                }
                $element.append($tr);

            }

            function watchDetails() {
                $element.empty();
                let $header = $(require('./details-not-unique-header.tpl.html'));
                $("td:eq(0)", $header).text($scope.item.exam.title);

                $("td:last > md-button", $header).attr('ng-click', 'deleteItem(item.exam)');

                $element.append($header);

                let index = 0;

                _.forEach($scope.item.value, function (value, uid) {
                    let detail = _.find($scope.item.exam.details, {uid: uid});
                    if (_.isNil(detail)) return

                    let interval = _.find(detail.settings.intervals, findInterval);

                    let $tr = $(require('./details-not-unique.tpl.html'));

                    $("td:eq(0)", $tr).text(detail.title);
                    // $("td:eq(0)", $tr).text(detail.title);
                    if (!_.isUndefined(interval))
                        $("td:eq(1)", $tr).text(`${_.get(interval, 'value.min', 0)} - ${_.get(interval, 'value.max', 0)}`);

                    if (!_.isUndefined($scope.item.last_value)) {
                        $("td:eq(2)", $tr).attr("ng-bind", `item.last_value['${uid}']`);
                    }

                    $("td:eq(4)", $tr).text(_.get(detail.settings.unit, 'value', ''));

                    let type = detail.settings.type;

                    if (type === "boolean") {
                        /*$("td:eq(5)", $tr).append('<md-icon md-font-icon="mdi-checkbox-blank-circle" md-font-set="mdi" ' +
                         'aria-label="status"></md-icon>');*/
                    }
                    if (!$scope.readOnly) {
                        let td = $("td:eq(3) ", $tr);
                        let comment = $("td:eq(5)", $tr);

                        if ($scope.isFirst && index == 0) td.attr('mn-auto-focus', "");

                        handleInput(type, td, "value");
                        $("input, md-checkbox", td).attr("ng-model", `item.value['${uid}']`);

                        handleInput(null, comment, "comment");
                        $("input", comment).attr("ng-model", `item.comments['${uid}']`);

                        $("td:last > md-button", $tr).attr('ng-click', `deleteDetail($event, "${uid}")`);

                    } else {
                        if (type !== "boolean") {
                            $("td:eq(3)", $tr).text($scope.item.value[uid]);
                            if (!_.isNull($scope.item.last_value)) {
                                $("td:eq(2)", $tr).attr("ng-bind", `item.last_value['${uid}']`);
                            }
                        } else {
                            if ($scope.item.value[uid]) {
                                $("td:eq(3)", $tr)
                                    .append('<md-icon md-font-icon="mdi-checkbox-marked" md-font-set="mdi" aria-label="status"></md-icon>');
                            } else {
                                $("td:eq(3)", $tr)
                                    .append('<md-icon md-font-icon="mdi-checkbox-blank" md-font-set="mdi" aria-label="status"></md-icon>');
                            }
                            if (!_.isNull($scope.item.last_value)) {
                                if ($scope.item.last_value[uid]) {
                                    $("td:eq(2)", $tr)
                                        .append('<md-icon md-font-icon="mdi-checkbox-marked" md-font-set="mdi" aria-label="status"></md-icon>');
                                } else {
                                    $("td:eq(2)", $tr)
                                        .append('<md-icon md-font-icon="mdi-checkbox-blank" md-font-set="mdi" aria-label="status"></md-icon>');
                                }
                            }
                        }
                        $("td:eq(5)", $tr).attr("ng-bind", `item.comments['${uid}']`);
                    }

                    index += 1;
                    $element.append($tr);
                });

                $compile($element.contents())($scope);
            }

            function findInterval(obj) {
                if (_.isEmpty(obj.value)) return false;

                if (_.isNull(obj.gender) || obj.gender == "UNDEFINED") {
                    return ageTest(obj);
                } else {
                    return obj.gender === _.get($scope.patientData, 'gender', null) && ageTest(obj);
                }
            }

            function ageTest(obj) {
                return (_.isUndefined(obj.age.min) ? true : obj.age.min <= _.get($scope.patientData, 'age', null))
                    && (_.isUndefined(obj.age.max) ? true : obj.age.max >= _.get($scope.patientData, 'age', null));
            }

            function handleInput(type, td, placeholder = null) {
                let placeholderSegment = _.isNil(placeholder) ? "" : `translate-once-placeholder="${placeholder}"`;

                switch (type) {
                    case "integer":
                        numberInput(td, 0, placeholder);
                        break;
                    case "float":
                        numberInput(td, precise_float, placeholder);
                        break;
                    case "boolean":
                        booleanCheck(td);
                        break;
                    default :
                        td.append(`<input ${placeholderSegment} />`);
                }
            }

            function numberInput(td, toFix, placeholder = null) {
                let $input = $('<input >')
                    .attr("type", "number")
                    .attr("mn-empty", "true")
                    .attr("ng-pattern", `'[0-9]+${toFix > 0 ? `\\.[0-9]{${toFix}}` : ""}'`)
                    .attr("mn-number", toFix);

                if (!_.isNil(placeholder)) $input.attr("translate-once-placeholder", placeholder);

                td.append($input);
            }

            function booleanCheck(td) {
                return td.append('<md-checkbox aria-label="value" ng-class="{\'md-indeterminate\': item.value == null}"></md-checkbox>');
            }

            function deleteItem(exam) {
                $scope.onDelete({exam: exam});
            }

            function deleteDetail($event, uid) {
                _.unset($scope.item.value, uid);
                let $tr = $($event.target).parents("tr:first");
                $tr.remove();
                if (_.isEmpty($scope.item.value)) {
                    deleteItem($scope.item.exam);
                }
            }
        }

        return directive;
    }

})();
