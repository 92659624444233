(function () {
    "use strict";

    module.exports = Generator;

    function Generator(generator, $translate) {
        let self = this;
        self.generator = generator;

        self.formulaParser = formulaParser;
        self.nextLine = nextLine;

        function formulaParser(options) {
            let formula = _.get(self.generator, "formula", "");
            const partQuantities = {
                "0.20": "1/5",
                "0.25": "1/4",
                "0.33": "1/3",
                "0.40": "2/5",
                "0.50": "1/2",
                "0.60": "3/5",
                "0.67": "2/3",
                "0.75": "3/4",
                "0.80": "4/5"
            };
            const frequencies = getFrequencies();
            const lets = {
                "[quantity]": getQuantity(),
                "[unit]": getUnit(),
                "[qte_unit]": getQuantityUnitPosology(),
                "[frequencies]": _.isEmpty(frequencies) ? "" : frequencies,
                "[period]": getPeriod()
            };

            _.forEach(lets, function (v, k) {
                formula = _.replace(formula, k, v);
            });

            return _.trimEnd(formula.replace(/(,){2,}/g, ", ")
                .replace(/(\s){2,}/g, " ")
                .replace(/(,\s|\s,){2,}/g, ", ")
                .replace(/(,\s,)/g, ", "), " ,");

            function getQuantity() {
                let value = _.get(options, 'quantity', 1);

                return partQuantities[parseFloat(value).toFixed(2)] || value;
            }

            function getFrequencies() {
                return _.reduce(options.frequencies, function (result, item) {
                    return _.concat(result, item.value);
                }, []).join(", ")
            }

            function getUnit() {
                let real_quantity = _.get(options, 'quantity', 1)

                let value = _.get(options.unit, "value", "");
                let plural_value = _.get(options.unit, "plural_value");

                if (real_quantity > 1 && !_.isNil(plural_value)) return plural_value;
                else return value;
            }

            function getQuantityUnitPosology() {
                return `${getQuantity()} ${getUnit()}`;
            }

            function getPeriod() {
                let prefix = $translate.instant(_.get(self.generator, "period_prefix", ""))
                let type = options.period.type;
                let length = options.period.quantity;

                if (length < 1) return "";

                let type_t = "";

                if (length > 1 && type !== "month_type") type_t = $translate.instant(type + "_plural");
                else type_t = $translate.instant(type);

                return `,${prefix} ${length} ${type_t}`;
            }
        }

        function nextLine(text) {
            let newLine = $translate.instant(_.get(self.generator, "new_line", ""));

            return `${text}\n${newLine} `;
        }
    }

})();