(function () {

    'use strict';

    class AppBackupConfigDialogCtrl {
        constructor($mdDialog, configService) {
            this.dialog = $mdDialog;
            this.configService = configService;

            this.types = [
                {value: "NO_FILES", label: "app_backup.no_files_backup"},
                {value: "ONLY_FILES", label: "app_backup.only_files_backup"},
                {value: "FULL", label: "app_backup.full_backup"}
            ]
            this.config = {};
            this.duplicatedPaths = false
        }

        static get $inject() {
            return ["$mdDialog", "configService"];
        }

        $onInit() {
            this.configService.getByHttp("backups_config")
                .then(data => {
                    this.config = data;
                });
        }

        cancel() {
            this.dialog.cancel();
        }

        addPath() {
            this.config.paths.push({
                title: "",
                path: ""
            });
        }

        removePath(index) {
            this.config.paths.splice(index, 1);
        }

        checkPaths(value, field) {
            this.duplicatedPaths = _.filter(this.config.paths, (item) => item[field] === value).length > 1;
        }

        submit() {
            if(this.duplicatedPaths) return false;

            this.config.paths = _.filter(this.config.paths, (item) => !_.isEmpty(item.title) && !_.isEmpty(item.path));
            this.configService.set({"backups_config": this.config})
                .then(() => this.cancel());
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: AppBackupConfigDialogCtrl,
        template: require("./app-backup-config-dialog.tpl.html"),
    };


})();