(function () {
    'use strict';

    module.exports = PrescriptionViewCtrl;

    PrescriptionViewCtrl.$inject = ["prescriptionService", "$transition$", "$scope"];

    function PrescriptionViewCtrl(prescriptionService, $transition$, $scope) {
        let vm = this;

        let currentType = _.get($transition$.params('to'), 'type');
        let currentPatient = _.get($transition$.params('to'), 'pId');
        let currentPrescription = _.get($transition$.params('to'), 'prescriptionId');

        vm.$onInit = init;

        function init() {
            prescriptionService.prescriptionType.next({type: currentType});
            prescriptionService.fileManager.next(false);

            prescriptionService.itemEdited.next({id: -1, type: null});
            prescriptionService.itemDuplicated.next({id: -1, type: null});
            prescriptionService.itemActive.next({id: currentPrescription, type: currentType});

            vm.type = currentType;
            vm.showFileManager = false;
            vm.currentPatient = currentPatient;

            vm.promise = prescriptionService.getPrescription(currentPrescription, currentType)
                .then(success);

            function success(data) {
                vm.prescription = data;
            }

            let itemDeletedSubscription = prescriptionService.itemDeleted.subscribe(itemDeleted);
            let fileManagerSubscription = prescriptionService.fileManager.subscribe(next => {
                vm.showFileManager = !!next;
            });

            function itemDeleted(item) {
                if (_.isEqual(item, {id: currentPrescription, prescription_type: currentType})) prescriptionService.listState();
            }

            $scope.$on("$destroy", onDestroy);

            function onDestroy() {
                itemDeletedSubscription.unsubscribe();
                fileManagerSubscription.unsubscribe();
            }
        }

    }
})();