/**
 * Created by Amine on 18/10/2021.
 */
(function () {

    'use strict';


    const EDITOR_CONTEXTUAL_TEMPLATE_DIALOG = require('stand-alone/text-editor/dialogs/editor-contextual-template-form/editor-contextual-template-form');

    class EditorContextualModelSetupCtrl {
        constructor(editorContextualModelService, $mdDialog, $translate) {
            this.editorContextualModelService = editorContextualModelService;
            this.dialog = $mdDialog;
            this.translate = $translate;

            this.reset = null;
            this.promise = null;
            this.filter = {is_deleted: {$ne: true}};
            this.actions = {
                single: [
                    {
                        icon: 'mdi-pencil',
                        label: 'edition',
                        resource: 'general-config',
                        action: 'update',
                        behavior: 'disable',
                        method: (item, event) => this.handleForm(item, event)
                    },
                    {
                        icon: 'mdi-star-check',
                        label: 'is_bookmarked',
                        resource: 'general-config',
                        action: 'update',
                        behavior: 'disable',
                        method: (item) => this.toggleBookmark(item)
                    },
                    {
                        icon: 'mdi-delete',
                        label: 'delete',
                        resource: 'general-config',
                        action: 'delete',
                        behavior: 'disable',
                        method: (item, event) => this.deleteItem(item, event)
                    }
                ],
                multiple: []
            };
        }

        static get $inject() {
            return ["editorContextualModelService", "$mdDialog", "$translate"];
        }

        $onInit() {

        }

        toggleBookmark(template) {
            const data = _.chain(template)
                .pick("id")
                .set("is_bookmarked", !template.is_bookmarked)
                .value();

            this.editorContextualModelService.saveModel(data, "put").then(() => this.reset());
        }

        deleteItem(item, event) {
            const confirm = this.dialog.confirm()
                .targetEvent(event)
                .clickOutsideToClose(true)
                .title('')
                .textContent(this.translate.instant('delete_warning'))
                .ariaLabel('delete_warning')
                .ok(this.translate.instant('confirm'))
                .cancel(this.translate.instant('cancel'))

            this.dialog.show(confirm).then(data => {
                if (data) {
                    this.editorContextualModelService.deleteModel(item).then(() => this.reset());
                }
            }, _.noop);
        }

        handleForm(item = null, event = null) {
            this.dialog.show(_.assign({}, EDITOR_CONTEXTUAL_TEMPLATE_DIALOG, {
                targetEvent: event,
                locals: {
                    model: item
                }
            })).then(() => this.reset(), _.noop);
        }
    }

    module.exports = EditorContextualModelSetupCtrl;

})();
