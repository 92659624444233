(function () {

    'use strict';

    class PrismaOrderDialogCtrl {
        constructor($mdDialog, CloudPlatformsService, $q, $translate) {
            this.dialog = $mdDialog;
            this.CloudPlatformsService = CloudPlatformsService;
            this.q = $q;
            this.translate = $translate;
        }

        static get $inject() {
            return ["$mdDialog", "CloudPlatformsService", "$q", "$translate"];
        }

        $onInit() {
            this.selectedDevices = [];
            this.assignedDevices = [];
            this.promise = this.q.all([this.CloudPlatformsService.getAssignedDevices(), this.CloudPlatformsService.getDevices()])
                .then(data => {
                    this.assignedDevices = data[0];
                    this.devices = _.reject(data[1], (d) => {
                        return _.includes(_.map(this.assignedDevices, "sn"), _.get(d, "deviceSn"));
                    });
                });
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.promise = this.CloudPlatformsService.assignDevice(_.get(this.selectedDevices, '0.deviceSn'), this.patientId)
                .then(data => {
                    if (!_.get(data, 'error')) {
                        this.dialog.hide(data);
                    } else {
                        this.dialog.show(
                            this.dialog.alert()
                                .parent($(document.body))
                                .clickOutsideToClose(true)
                                .title('')
                                .textContent(this.translate.instant("assign_device_error"))
                                .ariaLabel(_.get(data, 'error'))
                                .ok(this.translate.instant('ok'))
                                .multiple(true)
                        );
                    }
                });
        }

        getPatientAssignedDevice() {
            return _.find(this.assignedDevices, (e) => {
                return e.patient == this.patientId
            });
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: PrismaOrderDialogCtrl,
        template: require("../views/prisma-order-dialog.tpl.html"),
    };

})();
