/**
 * Created by BETALOS on 15/07/2016.
 */
(function () {
    'use strict';

    const FullScreen = require('shared/utils/full-screen');
    const AGENDA_DIALOG = require('frontdesk/dialogs/agenda-dialog');
    const ACCOUNT_UPDATE_DIALOG = require('auth/dialogs/account-update-dialog');
    const BACKUP_ALERT_LS_KEY = "BACKUP_ALERT_LS_KEY";
    const MAINTENANCE_ALERT_LS_KEY = "MAINTENANCE_ALERT_LS_KEY";

    const TOOLBAR_LINKS_ACL = [
        {resource: 'encasement', action: 'get'},
        {resource: 'exam', action: 'get'},
        {resource: 'visit', action: 'get'},
        {resource: 'states', action: 'get'}
    ];

    class ToolbarCtrl {
        constructor($scope, $element, mnSidenav, authService, $timeout, $mdDialog, $mdToast, configService,
                    visitService, system, $translate, maintenanceService, moment, $q) {
            this.scope = $scope;
            this.q = $q;
            this.timeout = $timeout;
            this.translate = $translate;
            this.dialog = $mdDialog;
            this.toast = $mdToast;
            this.sidenav = mnSidenav;
            this.authService = authService;
            this.configService = configService;
            this.maintenanceService = maintenanceService;
            this.moment = moment;

            this.isFullScreen = false;
            this.licencedFor = system['licenced_for'];
            this.maintenanceUntil = system['maintenance_until'];
            this.maintenanceDisabled = false;
            this.dateFormat = system['date_format'].js
            this.username = this.authService.user['username'];

            this.backupsSubscription = null;
            this.customLinks = [];

            visitService.visitSubLinks.subscribe(
                data => this.showRepportLink = !_.get(data, 'disabled.EXAM', false)
            );

            configService.toolbarLinks.subscribe(
                ({forms, order}) => this.customLinks = _.orderBy(forms, item => _.get(order, item.key), -1)
            );

            $(document).on("fullscreenchange webkitfullscreenchange", $element, () => this.handleFullScreenChange());
        }

        static get $inject() {
            return [
                "$scope", "$element", "mnSidenav", "authService", "$timeout", "$mdDialog", "$mdToast", "configService",
                "visitService", "system", "$translate", "maintenanceService", "moment", "$q"
            ];
        }

        $onInit() {
            this.currentYear = this.moment().format("YYYY");
            this.defaultState = this.authService.getDefaultState();

            this.sideNavSubscription = this.sidenav.sideNavSubject
                .subscribe(status => {
                    if (_.isNull(status)) return;

                    this.isShown = status;

                    if (status) this.sidenav.lastState('left');
                    else this.sidenav.close('left', false);
                });

            this.handleAppAlerts();
        }

        $onDestroy() {
            if (this.sideNavSubscription) this.sideNavSubscription.unsubscribe();
            if (this.backupsSubscription) this.backupsSubscription.unsubscribe();
        }

        sideNav() {
            this.sidenav.toggle("left");
        }

        updateAccount(ev) {
            this.dialog.show(_.assign({}, ACCOUNT_UPDATE_DIALOG, {
                locals: {},
                targetEvent: ev,
            }))
        }

        showAgenda(ev) {
            this.dialog.show(_.assign({}, AGENDA_DIALOG, {
                targetEvent: ev,
            }));
        }

        fullScreen() {
            if (document['webkitIsFullScreen']) FullScreen.cancelFullScreen();
            else FullScreen.goFullScreen();
        }

        handleFullScreenChange() {
            this.isFullScreen = document['webkitIsFullScreen'];
            this.scope.$applyAsync();
        }

        linkCount() {
            let hasModules = _.sumBy([
                !this.showRepportLink,
                !this.configService.hasStock(),
                !this.configService.hasCareSheet()
            ], i => _.toInteger(i));

            return {'max-width': `calc(100% / ${10 - (this.handleLinksAcl() + hasModules)})`};
        }

        handleLinksAcl() {
            return _.chain(TOOLBAR_LINKS_ACL).map(
                i => !this.authService.aclHandler(i)
            ).sumBy(i => _.toInteger(i)).value();
        }

        getDefaultStateLabel() {
            if (this.defaultState === 'app.frontDesk.index') return 'front_desk_link_label';
            else if (this.defaultState === 'app.patient') return 'patient_manage';
            else if (this.defaultState === 'app.workflow.daily-workflow') return 'visit_list';
            else if (this.defaultState === 'app.workflow.daily-workflow') return 'daily_workflow';
        }

        _getToast(msg, action = null) {
            const simpleToast = this.toast.simple()
                .textContent(this.translate.instant(msg)) //
                .position("bottom left")
                .highlightAction(true)
                .highlightClass('md-primary')
                .hideDelay(0);

            if (action) simpleToast.action(this.translate.instant(action));

            return this.toast.show(simpleToast);
        }

        _backupAlert(backups_config) {
            let internalError = _.get(backups_config, "internal_error", false);
            let disableBackupStartupAlert = _.get(backups_config, "disable_startup_alert", false);

            if (internalError) this.maintenanceService.internalErrorAlert();
            if (disableBackupStartupAlert) return false;

            let nextAlert = this.moment(localStorage.getItem(BACKUP_ALERT_LS_KEY), this.dateFormat);
            if (nextAlert.isValid() && nextAlert.isSameOrAfter(this.moment(), "day")) return false;

            this.maintenanceService.internalAlert(
                this.translate.instant("alerts.backup_reminder.title"),
                this.translate.instant("alerts.backup_reminder.message"),
                this.translate.instant("alerts.backup_reminder.switch_label"),
                {
                    waitingTime: 15,
                    action: (ev, close) => {
                        const GENERATE_DIALOG = require('parameters/dialogs/app-backup/app-backup-dialog');

                        this.dialog.show(_.assign({}, GENERATE_DIALOG, {
                            targetEvent: ev,
                            locals: {},
                        })).then(backup => {
                            this.promise = this.maintenanceService.saveBackup(backup)
                                .then(data => {
                                    close();
                                });
                        }, _.noop);
                    },
                    actionLabel: this.translate.instant("app_backup.button")
                })
                .then(data => {
                    if (data) {
                        localStorage.setItem(BACKUP_ALERT_LS_KEY, this.moment().add(7, "days").format(this.dateFormat))
                    }
                }, _.noop);


        }

        _backupSubscription() {
            this.backupsSubscription = this.maintenanceService.backupsNotifications()
                .subscribe(backup => {
                    if (!backup) return false;

                    if (backup.is_generated) {
                        this._getToast("app_backup.ready_notification", "app_backup.download").then(resp => {
                            if (resp === "ok") {
                                this.maintenanceService.downloadBackup(backup);
                            }
                        }, _.noop);
                    } else if (backup.has_failed && !backup.locked) {
                        this._getToast("app_backup.failed_notification", "app_backup.reload").then(resp => {
                            if (resp === "ok") {
                                this.maintenanceService.reloadBackup(backup);
                            }
                        }, _.noop);
                    } else if (backup.locked) {
                        this._getToast("app_backup.cs_notification").then(resp => {
                            if (resp === "ok") {
                                this.maintenanceService.reloadBackup(backup);
                            }
                        }, _.noop);
                    }
                });
        }

        _maintenanceAlert(maintenance) {
            let disableMaintenanceStartupAlert = _.get(maintenance, "disable_startup_alert", false);

            if (disableMaintenanceStartupAlert) return false;

            if (this.maintenanceUntil.isAfter(this.moment().add(1, "month"))) {
                return false;
            }

            let nextAlertM = this.moment(localStorage.getItem(MAINTENANCE_ALERT_LS_KEY), this.dateFormat);
            if (nextAlertM.isValid() && nextAlertM.isAfter(this.moment(), "day")) {
                this.maintenanceDisabled = true;
                return false;
            }

            let args = [
                this.translate.instant("alerts.maintenance.title"),
                `<p>${this.translate.instant("alerts.maintenance.message_1", {date: this.maintenanceUntilRaw})}</p>
<p>${this.translate.instant("alerts.maintenance.message_2", {date: this.maintenanceUntilRaw})} <br>
${this.translate.instant("alerts.maintenance.message_3", {date: this.maintenanceUntilRaw})}
</p>`,
                this.translate.instant("alerts.maintenance.switch_label"),
            ];
            if (!this.maintenanceUntil.isValid() || this.maintenanceUntil.isBefore(this.moment(), "day")) {
                this.maintenanceDisabled = true;
                args.push({waitingTime: 10});
            } else if (this.maintenanceUntil.isSameOrBefore(this.moment().add(1, "month"))) {
                args.push({waitingTime: 1});
            }

            if (args.length > 3) {
                this.maintenanceService.internalAlert(
                    ...args
                ).then(data => {
                    if (data) {
                        localStorage.setItem(MAINTENANCE_ALERT_LS_KEY, this.moment().add(15, "days").format(this.dateFormat))
                    }
                }, _.noop);
            }
        }

        handleAppAlerts() {
            this.q.all({
                backups_config: this.configService.getByHttp("backups_config"),
                maintenance: this.configService.getByHttp("maintenance")
            }).then(({backups_config, maintenance}) => {
                this.maintenanceUntilRaw = this.maintenanceUntil;
                this.maintenanceUntil = this.moment(this.maintenanceUntil, this.dateFormat);

                this._backupAlert(backups_config);
                this._maintenanceAlert(maintenance);
            })

        }
    }

    module.exports = {
        controllerAs: "vm",
        controller: ToolbarCtrl,
        template: require('shared/views/toolbar.tpl.html')
    };

})();
