/**
 * Created by amine on 03/09/2021.
 */
(function () {

    'use strict';

    const FIELDS = require("../json/external-patient-api-fields.json");

    class ExternalPatientAPICtrl {
        constructor(configService, externalConnectionService, $mdToast, $q, $translate) {
            this.configService = configService;
            this.externalConnectionService = externalConnectionService;
            this.toast = $mdToast;
            this.q = $q;

            this.config = {};
            this.fields = [];
            this.secrets = {};

            this.simpleToast = $mdToast.simple()
                .textContent($translate.instant('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(1500);

            this.httpMethods = [
                {
                    value: "get",
                    label: "GET"
                },
                {
                    value: "post",
                    label: "POST"
                },
                {
                    value: "put",
                    label: "PUT"
                },
                {
                    value: "patch",
                    label: "PATCH"
                }
            ];

            this.activeModules = _.cloneDeep(configService.activeModules);
            this.enabled = _.get(this.activeModules, "has_external_patient_api", false);

            // this.handleActiveModules = _.mnDelay(() => {
            //     this.activeModules.has_external_patient_api = this.enable;
            //
            //     configService.set({"active_modules": this.activeModules})
            //         .then(() => {
            //             configService.activeModules = _.cloneDeep(this.activeModules);
            //             this.toast.show(this.simpleToast);
            //         });
            // }, 500);
        }

        static get $inject() {
            return ["configService", "externalConnectionService", "$mdToast", "$q", "$translate"];
        }

        get emptyFields() {
            return _.isNil(this.fields) || _.isEmpty(this.fields);
        }

        get availableFields() {
            return _.chain(this.allFields)
                .reject(item => {
                    let hide = false;
                    if (_.has(item, "active_module")) {
                        hide ||= !this.configService.checkActiveModule(item.active_module);
                    }
                    return _.includes(_.keys(this.fields), item.field) || hide
                })
                .map(item => _.assign(item, {key: `${!!item.group ? item.group : "patient"}.${item.field}`}))
                .value();
        }

        $onInit() {
            const configKeys = ["patient_ext_api_config", "patient_ext_api_fields", "patient_custom_fields"];
            if (this.configService.checkActiveModule("has_technical_file")) configKeys.push("technical_file_fields");

            this.configService.get(configKeys)
                .then(({
                           patient_ext_api_config,
                           patient_ext_api_fields,
                           patient_custom_fields,
                           technical_file_fields
                       }) => {

                    this.allFields = this.externalConnectionService.getPatientAPIFields(patient_custom_fields, technical_file_fields);
                    this.config = patient_ext_api_config || {};
                    this.fields = patient_ext_api_fields || {};
                    if (this.emptyFields) {
                        this.fields = _.chain(FIELDS)
                            .cloneDeep()
                            .filter(["required", true])
                            .reduce((acc, item) => {
                                acc[item.field] = item;

                                return acc
                            }, {})
                            .value();
                    }
                })
        }

        submit() {
            const promises = [
                this.configService.set({
                    patient_ext_api_config: this.config,
                    patient_ext_api_fields: this.fields
                }, false, null)
            ];

            if (!!this.secrets.password) {
                const passwordEvent = "shared.PasswordStore.set_config";
                const password = _.get(this.secrets, "password")

                promises.push(this.configService.set({"patient_ext_api_password": password}, false, null, passwordEvent));
            }

            this.q.all(promises)
                .then(() => this.toast.show(this.simpleToast));
        }

        addField(item) {
            this.fields[item.field] = _.cloneDeep(item);
        }

        removeField(name) {
            this.fields = _.omit(this.fields, name);
        }
    }

    module.exports = ExternalPatientAPICtrl;

})();
