/**
 * Created by amine on 12/04/2018.
 */
(function () {
    'use strict';

    const VACCINE_DIALOG = require("specifics/dialogs/vaccine-form-dialog/vaccine-form-dialog");
    const CONFIG_DIALOG = require("specifics/dialogs/vaccination-config-form-dialog/vaccination-config-form-dialog");

    class VaccinationCalendarSetupCtrl {
        constructor(vaccinationService, $translate, $mdDialog, configService) {
            this.vaccinationService = vaccinationService;
            this.configService = configService;
            this.dialog = $mdDialog;

            this.dialogContext = this.dialogContext || false;
            this.promise = null;

            this.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            };
            this.options = [20, 15, 10, 5];

            this.vaccines = [];
            this.listTotal = 0;
            this.listQuery = {
                limit: 10,
                page: 1,
                order: "+id",
                search_all: ""
            };

            this.configs = [];
            this.configsTotal = 0;
            this.configsQuery = {
                limit: 10,
                page: 1,
                search_all: ""
            };

            this.configModel = {}


        }

        static get $inject() {
            return ["vaccinationService", "$translate", "$mdDialog", "configService"];
        }

        $onInit() {
            this.handleAutoSave = _.mnDelay(() => this._handleAutoSave(), 400);
            this.configService.getByHttp("vaccination_block_config")
                .then(data => {
                    this.configModel = data;
                });

            this.onConfigsReorder = order => this._onConfigsReorder(order);
            this.onConfigsPaginate = (page, limit) => this._onConfigsPaginate(page, limit);
            this.onVaccinesReorder = order => this._onVaccinesReorder(order);
            this.onVaccinesPaginate = (page, limit) => this._onVaccinesPaginate(page, limit);

            this.getVaccinesData();
            this.getConfigsData();
        }

        _handleAutoSave() {
            this.configService.set({"vaccination_block_config": this.configModel});
        }

        _onVaccinesReorder(order) {
            this.listQuery = _.assign(this.listQuery, {order: order});
            this.getVaccinesData();
        }

        _onVaccinesPaginate(page, limit) {
            this.listQuery = _.assign(this.listQuery, {page: page, limit: limit});
            this.getVaccinesData();
        }

        getVaccinesData() {
            this.promise = this.vaccinationService
                .vaccinesList(this.listQuery)
                .then(data => {
                    this.listTotal = data.length;
                    this.vaccines = data.list;

                    if (this.listTotal < ((this.listQuery.page - 1) * this.listQuery.limit))
                        this.listQuery.page = 1;

                });

        }

        vaccineForm(vaccine, event) {
            this.dialog
                .show(_.assign(VACCINE_DIALOG, {
                    targetEvent: event,
                    locals: {vaccine: vaccine}
                }))
                .then(() => this.getVaccinesData(), _.noop);
        }

        removeVaccine(vaccine) {
            this.vaccinationService.removeVaccine(vaccine)
                .then(() => this.getVaccinesData());
        }

        configForm(config, event) {
            this.dialog
                .show(_.assign(CONFIG_DIALOG, {
                    targetEvent: event,
                    locals: {config: config}
                }))
                .then(() => this.getConfigsData(), _.noop);
        }

        _onConfigsReorder() {
            this.configsQuery = _.assign(this.configsQuery, {order: order});
            this.getConfigsData();
        }

        _onConfigsPaginate(page, limit) {
            this.configsQuery = _.assign(this.configsQuery, {page: page, limit: limit});
            this.getConfigsData();
        }

        getConfigsData() {
            this.promise = this.vaccinationService
                .calendarConfigsList(this.configsQuery)
                .then(data => {
                    this.configsTotal = data.length;
                    this.configs = data.list;

                    if (this.configsTotal < ((this.configsQuery.page - 1) * this.configsQuery.limit))
                        this.configsQuery.page = 1;
                });
        }

        removeVaccinationConfig(config) {
            this.vaccinationService.removeVaccinationConfig(config)
                .then(() => this.getConfigsData());
        }

        cancel() {
            if (this.dialogContext) this.dialog.cancel();
        }
    }

    module.exports = VaccinationCalendarSetupCtrl;
})();