/**
 * Created by amine on 23/06/2022.
 */
(function () {
    'use strict';

    class ActiveModuleFilter {
        constructor(configService) {
            this.configService = configService;
            this.filterCall = input => !!_.get(this.configService, `activeModules.${input}`)
        }

        static create() {
            return new ActiveModuleFilter(...arguments).filterCall;
        }
    }

    ActiveModuleFilter.create.$inject = ['configService'];

    module.exports = ActiveModuleFilter.create;


})();