/**
 * Edited by Amine on 13/10/2021.
 */
(function () {

    'use strict';

    class PrescriptionListCtrl {
        constructor(prescriptionService, $transition$, system, $q, $scope, moment) {
            this.prescriptionService = prescriptionService;
            this.q = $q;
            this.scope = $scope;
            this.moment = moment;

            this.promise = null;
            this.list = [];
            this.dateFormat = system['date_format'].js;
            this.currentPatient = _.get($transition$.params('to'), 'pId');
        }

        static get $inject() {
            return ["prescriptionService", "$transition$", "system", "$q", "$scope", "moment"];
        }

        $onInit() {
            this.prescriptionService.prescriptionType.next(null);
            this.prescriptionService.itemActive.next({id: -1, type: null});
            this.prescriptionService.itemEdited.next({id: -1, type: null});
            this.prescriptionService.itemDuplicated.next({id: -1, type: null});

            this.promise = this.prescriptionService.getPrescriptionDetailedLists(this.currentPatient)
                .then(({prescriptions}) => {
                    this.list = _.chain(prescriptions)
                        .orderBy(item => {
                            item.constructed_title = this.prescriptionService.constructedTitle(item);

                            return this.moment(item.prescription_date, this.dateFormat).valueOf();
                        }, "desc")
                        .value()
                });
            //this.q.all([
            //     this.prescriptionService.getTextPrescriptions(this.currentPatient),
            //     this.prescriptionService.getMedicalPrescriptions(this.currentPatient),
            //     this.prescriptionService.getBiologyPrescriptions(this.currentPatient),
            //     this.prescriptionService.getProcedurePrescriptions(this.currentPatient),
            //     this.prescriptionService.getCarePrescriptions(this.currentPatient)
            // ]).then(data => {
            //     this.list = _.chain(data)
            //         .flatten()
            //         .orderBy(item => {
            //             item.constructed_title = this.prescriptionService.constructedTitle(item);
            //
            //             return this.moment(item.prescription_date, this.dateFormat).valueOf();
            //         }, "desc")
            //         .value()
            // });

            const itemDeletedSubscription = this.prescriptionService.itemDeleted.subscribe(item => _.remove(this.list, item));
            this.scope.$on("$destory", () => {
                if (this.itemDeletedSubscription) itemDeletedSubscription.unsubscribe();
            });
        }
    }

    module.exports = PrescriptionListCtrl;

})();
