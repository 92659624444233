/**
 * Created by amine on 23/06/2022.
 */
(function () {
    'use strict';

    class AgeFilter {
        constructor(system, $translate, moment) {
            this.dateFormat = system['date_format'].js;
            this.filterCall = input => {
                if (_.isNil(input)) return $translate['instant']('year_age', {age: 0});

                const now = moment();
                const birthDate = moment(input, dateFormat)

                const diffDuration = moment.duration(now.diff(birthDate));

                return $translate.instant('exact_age', {months: diffDuration.months(), years: diffDuration.years()});
            };
        }

        static create() {
            return new AgeFilter(...arguments).filterCall;
        }
    }

    AgeFilter.create.$inject = ['system','$translate','moment'];

    module.exports = AgeFilter.create;
})();