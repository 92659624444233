/**
 * Created by Hp on 08/03/2017.
 */


(function () {

    'use strict';

    var multiConvention = {
        controller: multiConventionCtrl,
        controllerAs: "vm",
        bindings: {
            conventions: "=ngModel",
            mnLabel: "=mnLabel",
            disabled: "=mnDisabled"

        },
        template: tpl,
    };

    multiConventionCtrl.$inject = ["$mdDialog", "conventionService"];

    function multiConventionCtrl($mdDialog, conventionService) {

        var vm = this;

        vm.$onInit = init;
        vm.transformChip = transformChip;

        vm.add = add;
        vm.showSelectTable = showSelectTable;
        vm.getConventions = conventionService.getConventions;
        vm.getSelectedChip = getSelectedChip;

        function init() {
            vm.conventions = [];
        }


        function transformChip(chip) {
            if (angular.isObject(chip)) {
                return chip;
            }
        }

        function add(ev) {
            $mdDialog.show(_.assign({}, require('../dialogs/convention-dialog'), {
                locals: {}, template: require('shared/views/convention-dialog.tpl.html'),
                clickOutsideToClose: false,
            })).then(done);

        }

        function showSelectTable() {
            $mdDialog.show(_.assign(require(('../dialogs/convention-dialog')), {
                locals: {
                    search: {is_deleted: false},
                }, template: require('shared/views/convention-search-dialog.tpl.html') // to do remove this & the dialog it self

            })).then(done);
        }

        function done(data) {
            refreshConventions(data);
        }

        function refreshConventions(data) {
            _.forEach(data, function (obj) {
                if (_.chain(vm.conventions).filter(function (item) {
                        return item['convention'].label == obj.label
                    }).value().length == 0) vm.conventions.push({convention: obj, is_default: false});
            });
        }

        function getSelectedChip(elem) {
            _.forEach(vm.conventions, function (item) {
                item.is_default = false;
            });
            elem.is_default = true;
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, attrs) {
        var required = $elem.is("[required]") ? " required " : "";
        var mndisabled = !_.isUndefined(attrs['mnDisabled']) ? ' ng-disabled="vm.disabled" ' : "";

        var label = attrs['mnLabel'];
        $elem
            .addClass("layout-column")
        if (!$elem.is('[flex]')) $elem.addClass("flex");
        return [
            '<div class="control-block layout-row">',
            '<label  translate-once="' + label + '"></label>',
            '<span flex></span>',
            '<md-button class="md-icon-button md-primary" ng-click="vm.showSelectTable()" aria-label="select"  ' + mndisabled + '>',
            '<md-icon md-font-icon="mdi-text-box-search" md-font-set="mdi"></md-icon>',
            '</md-button>',
            // '<md-button  class="md-icon-button" ng-click="vm.add($event)" aria-label="add"  ' + mndisabled + '>',
            // '<md-icon md-font-icon="mdi-plus" md-font-set="mdi"></md-icon>',
            // '</md-button>',
            '</div>',
            '<md-chips ng-model="vm.conventions" md-autocomplete-snap=""   md-transform-chip="vm.transformChip($chip)" md-require-match="false">',
            '<md-autocomplete  md-selected-item="vm.convention" md-search-text="vm.searchText"' +
            ' md-items="item in vm.getConventions(vm.searchText)" md-item-text="item.convention.label"' +
            'md-no-cache="true" md-autoselect="true" md-delay="60" placeholder="{{\'add\'|translate}}" ' + mndisabled + '> ',
            '<md-item-template> ',
            '<span md-highlight-text="vm.searchText" md-highlight-flags="i">{{ item.convention.label }}</span>',
            '</md-item-template>',
            '<md-not-found>',
            '<span translate="provider_not_found"></span>',
            '</md-not-found>',
            '</md-autocomplete>',
            '<span md-highlight-text="item.convention.label">{{item.convention.label}} </span>',
            '<md-not-found>',
            '<div class="chip-not-found">Not found. Click here to add new.</div>',
            '</md-not-found>',
            '</md-autocomplete>',
            '<md-chip-template  default-chip is-default="$chip.is_default" ng-click="vm.getSelectedChip($chip)">',
            '<span>',
            '<strong>{{$chip.convention.label}}</strong>',
            '</span>',
            '</md-chip-template>',
            '</md-chips>',

        ].join('');
    }


    module.exports = multiConvention;


})();