(function () {

    'use strict';

    class VisitFormsSubmoduleDialogCtrl {
        constructor($mdDialog) {
            this.dialog = $mdDialog;
        }

        static get $inject() {
            return ["$mdDialog"];
        }

        $onInit() {
            this.model = this.model ? _.cloneDeep(this.model) : {};
            this.category = {id: _.get(this.model, "category")}
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.dialog.hide(_.cloneDeep(this.model))
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: VisitFormsSubmoduleDialogCtrl,
        template: require("./visit-forms-submodule-dialog.tpl.html"),
    };

})();
