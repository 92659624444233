/**
 * Created by amine on 16/02/2017. edited 25/02/2020
 */
(function () {

    'use strict';

    class MainPaymentContainerCtrl {
        constructor($translate, paymentService, $state, $scope) {
            this.paymentService = paymentService;
            this.translate = $translate;
            this.state = $state;
            this.$scope = $scope;

            this.patient = null;
            this.paginationLabel = {
                page: this.translate.instant('page'),
                rowsPerPage: this.translate.instant('rowsPerPage'),
                of: this.translate.instant('of')
            };

            this.options = [20, 15, 10, 5];

            this.selectedEncasement = null;
            this.encasementPromise = null;
            this.encasements = [];
            this.encasementTotal = 0;
            this.encasementQuery = {
                limit: 10,
                page: 1,
                order: "-encasement_date",
                search_all: "",
                start_date: null,
                end_date: null,
                payment_mode: null,
                physician: null
            };
            this.encasementSummary = {
                total_amount: '-',
                consumed_amount: '-',
                remaining_amount: '-'
            };

            this.selectedAccount = null;
            this.accountPromise = null;
            this.accounts = [];
            this.accountTotal = 0;
            this.accountBalance = 0;

            this.accountQuery = {
                limit: 10,
                page: 1,
                order: "name",
                only_neg: true,
                account_type: "P"
            };

            this.balance = 0;
            this.mainModule = true;

            this.onEncasementReorder = order => this._onEncasementReorder(order);
            this.onEncasementPaginate = (page, limit) => this._onEncasementPaginate(page, limit);
            this.onAccountReorder = order => this._onAccountReorder(order);
            this.onAccountPaginate = (page, limit) => this._onAccountPaginate(page, limit);
        }

        static get $inject() {
            return ["$translate", "paymentService", "$state", "$scope"];
        }

        $onInit() {
            //this.getEncasementData();
        }

        newEncasement() {
            this.paymentService.encasementState();
        }

        datesQueryChanges(start, end) {
            this.$scope.$applyAsync(() => {
                this.encasementQuery.start_date = start;
                this.encasementQuery.end_date = end;
                this.getEncasementData();
            });
        }

        getEncasementData() {
            this.encasementPromise = this.paymentService.getEncasements(this.encasementQuery)
                .then(data => {
                    this.selectedEncasement = null;
                    this.encasementTotal = data.length;
                    this.encasements = data.list;

                    if (this.encasementTotal < ((this.encasementQuery.page - 1) * this.encasementQuery.limit))
                        this.encasementQuery.page = 1;

                    this.paymentService.getEncasementsSummary(this.encasementQuery).then(data => {
                        this.encasementSummary = _.assign(this.encasementSummary, {
                            total_amount: data.total_amount,
                            consumed_amount: data.consumed_amount,
                            remaining_amount: data.remaining_amount
                        });
                    })
                }, _.noop);


        }

        showEncasementDetails(encasement) {
            if (this.selectedEncasement === encasement.id) {
                this.selectedEncasement = -1;
            } else {
                this.selectedEncasement = encasement.id;
            }
        }

        _onEncasementReorder(order) {
            this.encasementQuery = _.assign(this.encasementQuery, {order: order});
            this.getEncasementData();
        }

        _onEncasementPaginate(page, limit) {
            this.encasementQuery = _.assign(this.encasementQuery, {page: page, limit: limit});
            this.getEncasementData();
        }

        getAccountData() {
            this.accountPromise = this.paymentService.getGeneralAccount(this.accountQuery)
                .then(data => {
                    this.selectedEncasement = null;
                    this.accountTotal = data.length;
                    this.accountBalance = data.balance.value;
                    this.accounts = data.list;

                    if (this.accountTotal < ((this.accountQuery.page - 1) * this.accountQuery.limit))
                        this.accountQuery.page = 1;
                }, _.noop);
        }

        showAccountDetails(account) {
            if (this.selectedAccount === account.id) {
                this.selectedAccount = -1;
            } else {
                this.selectedAccount = account.id;
            }
        }

        loadGeneralAccountData() {
            if (_.isNil(this.accountPromise)) this.getAccountData();
        }

        _onAccountReorder(order) {
            this.accountQuery = _.assign(this.accountQuery, {order: order});
            this.getAccountData();
        }

        _onAccountPaginate(page, limit) {
            this.accountQuery = _.assign(this.accountQuery, {page: page, limit: limit});
            this.getAccountData();
        }
    }

    module.exports = MainPaymentContainerCtrl;

})();