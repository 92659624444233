(function () {

    'use strict';

    const AET_DIALOG = require('stand-alone/dcm/dialogs/aet/aet-dialog');
    const VIEWER_DIALOG = require('stand-alone/dcm/dialogs/dcm-viewer/dcm-viewer-dialog');
    const REQUESTED_PROCEDURE_DIALOG = require('stand-alone/dcm/dialogs/requested-procedure/requested-procedure-dialog');

    class DcmConfigCtrl {
        constructor($mdDialog, dcmService, $q, $mdToast, $translate) {
            this.$q = $q;
            this.toast = $mdToast;
            this.dialog = $mdDialog;
            this.dcmService = dcmService;
            this.translate = $translate;

            this.simpleToast = $mdToast.simple()
                .textContent($translate.instant('dcm_modality_update_success'))
                .position("bottom left")
                .hideDelay(1500);

            this.errorToast = $mdToast.simple()
                .position("bottom left")
                .hideDelay(5000);

            this.showAet = true;
            this.showViewer = false;
            this.showRequestedProcedure = false;

            this._delayedCallback = _.debounce(() => this._serviceCallbackDone(), 300);
            this._delayedUpdateSuccess = _.debounce(() => $mdToast.show(this.simpleToast), 400);
        }

        static get $inject() {
            return ["$mdDialog", "dcmService", "$q", "$mdToast", "$translate"];
        }

        $onInit() {
            this.promise = this.$q.all([
                this.dcmService.getAets(),
                this.dcmService.getViewers(),
                this.dcmService.getModalities(),
                this.dcmService.getRequestedProcedures(),
            ]).then(data => {
                this.aets = data[0];
                this.viewers = data[1];
                this.modalities = data[2];
                this.requestedProcedures = data[4];
                this.firePacsConfig = this.dcmService.integrationConfig || {};

                // dcm services
                this.servicePromise = this.dcmService.getDcmServiceConfigs().then(servicesConfig => {
                    this._handleServiceStatus(servicesConfig);
                });
            });
        }

        // dcm aet related
        handleAet(aet, ev) {
            this.dialog.show(_.assign({}, AET_DIALOG, {
                targetEvent: ev,
                locals: {
                    aet: _.cloneDeep(aet)
                }
            })).then(data => this.aets = _.pushOrUpdate(this.aets, data));
        }

        removeAet(aet, ev) {
            let confirm = this._getConfirmDialog('dcm_aet_remove_confirm', aet.title, ev);

            this.dialog.show(confirm)
                .then(() => {
                    this.promise = this.dcmService.removeAet(aet).then(() => this.aets = _.without(this.aets, aet));
                });
        }

        // requested procedure
        handleRequestProcedure(procedure, ev) {
            this.dialog.show(_.assign({}, REQUESTED_PROCEDURE_DIALOG, {
                targetEvent: ev,
                locals: {
                    procedure: _.cloneDeep(procedure)
                }
            })).then(data => this.requestedProcedures = _.pushOrUpdate(this.requestedProcedures, data));
        }

        removeRequestProcedure(procedure, ev) {
            let confirm = this._getConfirmDialog('dcm_requested_procedure_remove_confirm', procedure.label, ev);

            this.dialog.show(confirm)
                .then(() => {
                    this.promise = this.dcmService.removeAet(procedure)
                        .then(() => this.requestedProcedures = _.without(this.requestedProcedures, procedure));
                });
        }

        // modalities activation handling
        updateModality(modality) {
            this.dcmService.updateModality(modality)
                .then(() => this._delayedUpdateSuccess());
        }

        //

        // dcm viewer related
        handleViewer(viewer, ev) {
            this.dialog.show(_.assign({}, VIEWER_DIALOG, {
                targetEvent: ev,
                locals: {
                    viewer: _.cloneDeep(viewer)
                }
            })).then(data => this.viewers = _.pushOrUpdate(this.viewers, data));
        }

        removeViewer(viewer, ev) {
            let confirm = this._getConfirmDialog('dcm_viewer_remove_confirm', viewer.title, ev);

            this.dialog.show(confirm)
                .then(() => this.promise = this.dcmService.removeViewer(viewer)
                    .then(() => this.viewers = _.without(this.viewers, viewer))
                );
        }

        //

        // dcm services related
        startService(name) {
            this.servicePromise = this.dcmService.handleDcmServiceConfig(this[name])
                .then(() => {
                    this.servicePromise = this.dcmService.startDcmService(this[name])
                        .then(() => this._delayedCallback(), err => this._serviceCallbackError(err));
                });
        }

        stopService(name) {
            this.servicePromise = this.dcmService.stopDcmService(this[name])
                .then(() => this._delayedCallback(), err => this._serviceCallbackError(err));
        }

        restartService(name) {
            this.servicePromise = this.dcmService.restartDcmService(this[name])
                .then(() => this._delayedCallback(), err => this._serviceCallbackError(err));
        }

        //

        // fire pacs integration
        updateFirePacsConfig() {
            this.dcmService.setIntegrationConfig(this.firePacsConfig)
                .then(() => this._delayedUpdateSuccess());
        }

        // private methods
        _getConfirmDialog(key, title, ev) {
            return this.dialog.confirm()
                .targetEvent(ev)
                .ariaLabel('remove plan confirm')
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'))
                .title(this.translate.instant(key, {title}));
        }

        // service related
        _serviceCallbackDone() {
            this.servicePromise = this.dcmService.getDcmServiceConfigs()
                .then(data => this._handleServiceStatus(data));
        }

        _handleServiceStatus(data) {
            console.log(data);
            this.mwl = _.find(data, {type: 'mwl'}) || {type: 'mwl'};
            this.qr = _.find(data, {type: 'qr'}) || {type: 'qr'};
            this.store = _.find(data, {type: 'store'}) || {type: 'store'};
        }

        _serviceCallbackError(err) {
            let msg = this.errorToast.textContent(err);
            this.toast.show(msg);
        }

        //

    }

    module.exports = DcmConfigCtrl;

})();
