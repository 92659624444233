/**
 * Created by BETALOS on 23/01/2017.
 */
(function () {

    'use strict';

    const PROCEDURE_DIALOG = require('../dialogs/procedure-form/procedure-form-dialog');

    module.exports = ProcedureSetupCtrl;

    ProcedureSetupCtrl.$inject = [
        "visitService", "$mdDialog", "$translate", "dragulaService", "configService", "$mdToast", "$scope", "mnWebSocket"
    ];

    function ProcedureSetupCtrl(visitService, $mdDialog, $translate, dragulaService, configService, $mdToast, $scope, mnWebSocket) {
        let vm = this;
        let bag = "item-bag";

        vm.$onInit = init;
        vm.handleProcedure = handleProcedure;
        vm.removeProcedure = removeProcedure;
        vm.exportExcel = exportExcel;

        function init() {
            vm.promise = visitService.getProcedures()
                .then(success);

            function success(data) {
                vm.procedures = data;
            }

            dragulaService.options($scope, bag, {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });

            let endSortDelay = _.mnDelay(endSort, 500);
            let drake = dragulaService.find($scope, bag).drake;

            drake.on("dragend", () => endSortDelay());
        }

        function endSort() {
            configService.set({"procedure_sort": getSortConfig()}, true)
                .then(success);
        }

        function getSortConfig() {
            return _.reduce(vm.procedures, function (config, item, key) {
                return _.set(config, item.id.toString(), key);
            }, {})
        }

        function success() {
            const simpleToast = $mdToast.simple()
                .textContent($translate.instant('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(1500);

            $mdToast.show(simpleToast);
        }

        function handleProcedure(procedure, ev) {
            $mdDialog.show(_.assign({}, PROCEDURE_DIALOG, {
                targetEvent: ev,
                locals: {
                    model: _.cloneDeep(procedure)
                }
            })).then(done);

            function done(data) {
                vm.procedures = _.pushOrUpdate(vm.procedures, data);
            }
        }

        function removeProcedure(procedure, ev) {
            const confirm = $mdDialog.confirm()
                .title($translate.instant('procedure_remove_confirm', {title: procedure.name}))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate.instant('confirm_ok'))
                .cancel($translate.instant('confirm_cancel'));

            $mdDialog.show(confirm).then(remove);

            function remove() {
                vm.promise = visitService.removeProcedures(procedure)
                    .then(done)
            }

            function done() {
                _.pull(vm.procedures, procedure);
            }
        }

        function exportExcel() {
            return mnWebSocket.call("shared.procedure.ProcedureExport.generate_excel", {
                "code": $translate.instant('procedure_setup_code'),
                "name": $translate.instant('procedure_setup_name'),
                "price": $translate.instant('procedure_setup_price'),
                "sheet_name": $translate.instant('procedure_setup'),
                "file_name": $translate.instant('procedure_setup')
            }).then(file => {
                $mdToast.show($mdToast.simple()
                    .textContent($translate.instant('table_export_excel_in_progress'))
                    .position("bottom left")
                    .hideDelay(3000));

                file.download().then(() => {
                    $mdToast.show($mdToast.simple()
                        .textContent($translate.instant('table_export_excel_success'))
                        .position("bottom left")
                        .hideDelay(1500));
                });
            });
        }

    }

})();
